import { ReactNode } from "react";

import { DialogWrapper, DialogWrapperProps } from "@bridgesplit/ui";

import TransactionsWalletConnect from "./wallet-errors/TransactionsWalletConnect";
import { WalletConnectError, useConnectingWalletErrors } from "./wallet-errors";

export default function WalletValidatorWrapper({
    children,
    dialog
}: {
    children: ReactNode;
    dialog?: { description: string; props?: Partial<DialogWrapperProps> };
}) {
    const { error: walletError, isLoading: walletLoading } = useConnectingWalletErrors();

    if (walletLoading) return null;

    if (walletError !== WalletConnectError.None) {
        const connect = (
            <TransactionsWalletConnect
                header={dialog ? { description: dialog.description } : undefined}
                error={walletError}
                isLoading={walletLoading}
            />
        );

        if (dialog) {
            return <DialogWrapper {...dialog.props}>{connect}</DialogWrapper>;
        }
        return connect;
    }

    // Resolves the transaction promise as soon as this is returned hit
    return <> {children} </>;
}
