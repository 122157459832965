import { formatStatNum, formatUsd } from "@bridgesplit/utils";
import { Column, Row, Table, TableColumn, Text, createRows } from "@bridgesplit/ui";
import { BsMetaUtil, LoopscalePointSource, PointSource, RoleView } from "@bridgesplit/abf-react";

import { RateAndRewardsBreakdown } from "../../points";
import { MarketStats } from "./types";
import { BorrowCapWarning, getMarketPath, TokenImage } from "../../common";
import MarketDetailCta from "./MarketDetailCta";
export default function PrincipalMarketsTable({
    marketStats,
    view
}: {
    marketStats: MarketStats[] | undefined;
    view: RoleView;
}) {
    return (
        <Table
            onRowNavigatePath={({ data }) => getMarketPath({ token: data.metadata, view })}
            searchPlaceholder="Search by asset name"
            paginationProps={{
                pageSize: 10,
                type: "managed",
                defaultSortKey: "supplied"
            }}
            emptyPlaceholder="No markets found"
            loading={!marketStats}
            rows={createRows(marketStats, (s) => s.metadata.assetMint)}
            columns={getColumns(view, marketStats)}
        />
    );
}

const getLendApy = (marketStats: MarketStats) => Math.max(marketStats.apy ?? 0, marketStats.yieldVault?.apy ?? 0);
const getColumns = (view: RoleView, marketStats: MarketStats[] | undefined): TableColumn<MarketStats>[] => [
    {
        dataIndex: "token",
        title: "Token",
        render: ({ data: { metadata } }) => (
            <Row spacing={1}>
                <TokenImage metadata={metadata} size="md" />
                <Text>{BsMetaUtil.getSymbol(metadata)}</Text>
            </Row>
        ),
        sortBy: ({ data: { metadata } }) => BsMetaUtil.getSymbol(metadata)
    },
    view === RoleView.Borrow
        ? {
              align: "right",
              dataIndex: "borrowAPY",
              title: "Borrow APY",
              width: "20%",
              render: ({ data }) => (
                  <RateAndRewardsBreakdown
                      rateDisplayProps={{
                          allApys: marketStats?.map((m) => m.apy ?? 0)
                      }}
                      apy={data.apy ?? 0}
                      pointSources={[PointSource.Loopscale]}
                      loopscalePointSources={[LoopscalePointSource.Borrow]}
                      metadata={[data.metadata]}
                  />
              ),
              sortBy: ({ data }) => data.apy ?? 0
          }
        : {
              align: "right",
              dataIndex: "lendApy",
              title: "Lend APY",
              width: "20%",
              render: ({ data }) => (
                  <RateAndRewardsBreakdown
                      rateDisplayProps={{
                          allApys: marketStats?.map((m) => getLendApy(m))
                      }}
                      apy={getLendApy(data)}
                      pointSources={[PointSource.MarginFi, PointSource.Loopscale]}
                      loopscalePointSources={[LoopscalePointSource.Lend]}
                      metadata={[data.metadata]}
                  />
              ),
              sortBy: ({ data }) => getLendApy(data)
          },
    {
        dataIndex: "supplied",
        title: "Supplied",
        align: "right",
        render: ({ data: { available, availableUsd, borrowed, borrowedUsd } }) => (
            <Column alignItems="flex-end">
                <Text color="body">{formatStatNum(available + borrowed)}</Text>
                <Text loading={availableUsd === undefined || borrowedUsd === undefined} variant="body2" color="caption">
                    {formatUsd((availableUsd ?? 0) + (borrowedUsd ?? 0))}
                </Text>
            </Column>
        ),
        sortBy: ({ data: { availableUsd, borrowedUsd } }) => (availableUsd ?? 0) + (borrowedUsd ?? 0)
    },
    {
        dataIndex: "borrowed",
        title: "Borrowed",
        align: "right",
        render: ({ data: { borrowed, borrowedUsd, borrowCapWarning } }) => (
            <Column alignItems="flex-end">
                <BorrowCapWarning borrowCapWarning={borrowCapWarning}>{formatStatNum(borrowed ?? 0)}</BorrowCapWarning>

                <Text loadingWidth="50px" loading={borrowedUsd === undefined} variant="body2" color="caption">
                    {formatUsd(borrowedUsd)}
                </Text>
            </Column>
        ),
        sortBy: ({ data: { borrowedUsd } }) => borrowedUsd || 0
    },

    {
        dataIndex: "actions",
        title: "",
        align: "right",
        render: ({ data: { metadata } }) => <MarketDetailCta token={metadata} view={view} />
    }
];
