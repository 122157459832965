import { useState } from "react";

import { Button, DialogWrapper, LabelWrapper, PercentInput, StatColumn } from "@bridgesplit/ui";
import { formatPercent, PERCENT_DECIMALS } from "@bridgesplit/utils";
import { COPY } from "app/constants";
import { AppDialog } from "app/utils";

import { useMarketDialog } from "../util";
import MarketDialogHeader from "./common";

export default function LendApyDialog() {
    return (
        <DialogWrapper>
            <LendApyDialogInternal />
        </DialogWrapper>
    );
}

export function LendApyDialogInternal() {
    const { getData, close } = useMarketDialog();
    const data = getData(AppDialog.LendApy);

    const [apy, setApy] = useState<number | undefined>(data?.currentApy);

    return (
        <>
            <MarketDialogHeader header="Set custom APY" />
            <LabelWrapper label={COPY.STRATEGY_APY_TERM} tooltip={COPY.STRATEGY_APY_TOOLTIP}>
                <PercentInput value={apy} setValue={setApy} />
            </LabelWrapper>

            <StatColumn
                stats={[
                    {
                        caption: "Market rate",
                        tooltip: "The midpoint between the borrow and lend APY on other lending protocols",
                        value: formatPercent(data?.defaultRate, { customDecimals: PERCENT_DECIMALS })
                    },
                    {
                        caption: "Loopscale rate",
                        tooltip: "The lowest APY on the Loopscale credit book",
                        value: formatPercent(data?.marketMinApy, { customDecimals: PERCENT_DECIMALS })
                    }
                ]}
            />
            <Button
                variant="contained"
                onClick={() => {
                    data?.setApy(apy);
                    close();
                }}
            >
                Save APY
            </Button>
        </>
    );
}
