import { DialogWrapper } from "@bridgesplit/ui";
import { AppDialog, useAppDialog } from "app/utils";

import { useSphereRefresh } from "../util";

export default function SphereAcceptTermsDialog() {
    const { getData } = useAppDialog();
    const url = getData(AppDialog.SphereAcceptTerms)?.url;
    const refresh = useSphereRefresh();
    if (!url) return null;

    return (
        <DialogWrapper
            onClose={refresh}
            sx={{ background: "white", height: "800px", justifyContent: "center" }}
            padding={0}
        >
            <iframe width="100%" height="100%" style={{ border: "none" }} title="Sphere" src={url + "&iframe=true"} />
        </DialogWrapper>
    );
}
