import {
    Column,
    DIALOG_PADDING,
    DialogWrapper,
    FONT_SIZES,
    IconWithBackground,
    Image,
    Link,
    Row,
    Text,
    useAppPalette
} from "@bridgesplit/ui";
import { AppDialog } from "app/utils";
import { formatDurationWithType } from "@bridgesplit/abf-sdk";
import { COPY } from "app/constants";
import { SettingsOutlined } from "@mui/icons-material";

import MarketDialogHeader from "./common";
import { useMarketDialog, usePreserveMarketData } from "../util";
import { getMaxDurationForTemplate, OverlappingMetadataImages } from "../../common";

export default function TemplateSelectDialog() {
    return (
        <DialogWrapper>
            <TemplateSelectDialogInternal />
        </DialogWrapper>
    );
}

export function TemplateSelectDialogInternal() {
    const { close, getData } = useMarketDialog();

    const data = getData(AppDialog.TemplateSelect);
    const { hoverBackground } = useAppPalette();

    const { detailPath, onDetailNavigate } = usePreserveMarketData();
    return (
        <Column spacing={2}>
            <MarketDialogHeader header={`Select ${COPY.STRATEGY_TEMPLATE_TERM_LOWER}`} />

            <Column sx={{ mx: -DIALOG_PADDING }}>
                <Row sx={{ px: DIALOG_PADDING, py: 1 }} spaceBetween>
                    <Text color="caption" variant="body2">
                        {COPY.STRATEGY_TEMPLATE_TERM}
                    </Text>
                    <Text color="caption" variant="body2">
                        {COPY.ALLOWED_COLLATERAL_TERM}
                    </Text>
                </Row>
                {data?.templates.map((t) => {
                    const maxDuration = getMaxDurationForTemplate(t);

                    return (
                        <Row
                            key={t.template.identifier}
                            spaceBetween
                            sx={{
                                cursor: "pointer",
                                px: DIALOG_PADDING,
                                py: 1,
                                "&:hover": { background: hoverBackground }
                            }}
                            onClick={() => {
                                data.select(t);
                                close();
                            }}
                        >
                            <Row spacing={1.5}>
                                <Image variant="circle" size="30px" src={t.template.logo} />
                                <Column>
                                    <Text>{t.template.name}</Text>
                                    <Text variant="body2" color="caption">
                                        Up to {formatDurationWithType(maxDuration?.duration, maxDuration?.durationType)}
                                    </Text>
                                </Column>
                            </Row>
                            <OverlappingMetadataImages size={FONT_SIZES.h3 + "px"} metadata={t.collateral} />
                        </Row>
                    );
                })}
                <Link sx={{ with: "100%" }} to={detailPath} onClick={onDetailNavigate}>
                    <Row
                        spacing={1.5}
                        sx={{
                            flexGrow: 1,
                            cursor: "pointer",
                            px: DIALOG_PADDING,
                            py: 1,
                            "&:hover": { background: hoverBackground }
                        }}
                        onClick={onDetailNavigate}
                    >
                        <IconWithBackground size={30}>
                            <SettingsOutlined />
                        </IconWithBackground>
                        <Column>
                            <Text>Want more flexibility?</Text>
                            <Text variant="body2" color="caption">
                                Create custom {COPY.STRATEGY_TERM_PLURAL.toLowerCase()} on the credit book
                            </Text>
                        </Column>
                    </Row>
                </Link>
            </Column>
        </Column>
    );
}
