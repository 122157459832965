import { memo } from "react";

import { Column } from "@bridgesplit/ui";
import { RoleView } from "@bridgesplit/abf-react";

import LendSettings from "./LendSettings";
import LendAmount from "./LendAmount";
import LendCta from "./LendCta";
import LendCollateralSelect from "./LendCollateralSelect";
import {
    useInitializeMarketLendCollateral,
    useInitializeMarketLendPresets,
    useInitializeMarketLendYieldSource,
    useSetDefaultApy
} from "./util";
import BorrowCapWarning from "../common/BorrowCapWarning";
import LendApyDuration from "./LendApyDuration";
import { useOpenGuideOnLoad } from "../util";
import LendLiteStats from "./LendLiteStats";
import { useMarketContext } from "../common";

export default memo(function LendCard() {
    useInitializeMarketLendCollateral();
    useInitializeMarketLendPresets();
    useSetDefaultApy();
    useInitializeMarketLendYieldSource();
    useOpenGuideOnLoad({ view: RoleView.Lend });

    const { isDialog } = useMarketContext();
    return (
        <Column spacing={3}>
            <LendAmount />

            {isDialog ? (
                <LendLiteStats />
            ) : (
                <>
                    <LendCollateralSelect />
                    <LendApyDuration />
                    <LendSettings />
                </>
            )}

            <BorrowCapWarning />
            <LendCta />
        </Column>
    );
});
