import { useEffect, useMemo } from "react";

import {
    RateMarketType,
    StrategyTemplateExpanded,
    useExternalYieldVaults,
    useRatesHistoryQuery
} from "@bridgesplit/abf-react";
import { deepCompareMaps, percentDecimalsToUi, percentUiToDecimals, TIME } from "@bridgesplit/utils";
import { ExternalYieldSource } from "@bridgesplit/abf-sdk";

import { useMarketContext, useQuotesContext } from "../common";
import { useMarketLendContext } from "./MarketLendContext";
import { isStrategyDurationSame, useMarketCollateral } from "../util";
import { getMaxDurationForTemplate } from "../../common";

export function useInitializeMarketLendCollateral() {
    const {
        form: { collateralInitializedForMint },
        setForm
    } = useMarketLendContext();
    const supportedCollateral = useMarketCollateral();
    const { principalMint } = useMarketContext();

    const defaultMints = useMemo(() => {
        if (!supportedCollateral) return undefined;
        return supportedCollateral.map((c) => c.assetMint);
    }, [supportedCollateral]);

    useEffect(() => {
        if (!principalMint || !defaultMints?.length || collateralInitializedForMint === principalMint) return;

        setForm((prev) => ({
            ...prev,
            collateralInitializedForMint: principalMint,
            collateral: defaultMints
        }));
    }, [defaultMints, setForm, principalMint, collateralInitializedForMint]);
}

export function useInitializeMarketLendYieldSource() {
    const { principalMint } = useMarketContext();
    const { getYieldVault, isLoading } = useExternalYieldVaults();
    const { form, setForm } = useMarketLendContext();
    const yieldVault = getYieldVault(principalMint);

    useEffect(() => {
        if (isLoading || yieldVault?.yieldSource === form.yieldSource) return;

        setForm((prev) => ({
            ...prev,
            yieldSource: ExternalYieldSource.None
        }));
    }, [principalMint, yieldVault, isLoading, setForm, form.yieldSource]);
}

export function useSetDefaultApy() {
    const { template } = useLendTemplateDetails();
    const { form, setForm, isTemplateBased } = useMarketLendContext();
    const { defaultRate } = useLendMarketRates();
    const { principalMint } = useMarketContext();

    const presetToApy = useMemo(() => {
        if (!template || !defaultRate || !isTemplateBased) return undefined;

        return new Map<string, number | undefined>(
            template.presets.map((p) => [p.strategyTerms.presetStrategyIdentifier, percentDecimalsToUi(defaultRate)])
        );
    }, [defaultRate, isTemplateBased, template]);

    useEffect(() => {
        if (
            !principalMint ||
            form.templateApyInitializedForMint === principalMint ||
            !presetToApy ||
            deepCompareMaps(presetToApy, form.presetToApy)
        )
            return;

        setForm((prev) => ({ ...prev, presetToApy, templateApyInitializedForMint: principalMint }));
    }, [form.presetToApy, form.templateApyInitializedForMint, presetToApy, setForm, principalMint]);
}

export function useInitializeMarketLendPresets() {
    const { principalMint } = useMarketContext();
    const {
        form: { presetToApy },
        setForm,
        presets
    } = useMarketLendContext();

    // fine the init presets are based on collateral not principal
    useEffect(() => {
        if (!principalMint || !presets?.length || !!presetToApy.size) return;

        setForm((prev) => ({
            ...prev,
            presetToApy: new Map(presets?.map((r) => [r.presetStrategyIdentifier, undefined]) ?? [])
        }));
    }, [principalMint, presets, setForm, presetToApy.size]);
}

export function useSelectStrategyTemplate() {
    const { setForm } = useMarketLendContext();
    const { setCollateralMints: setQuoteCollateral } = useQuotesContext();

    return (strategyTemplate: StrategyTemplateExpanded) => {
        const collateral = strategyTemplate.collateral.map((c) => c.assetMint);
        setForm((prev) => ({
            ...prev,
            collateral,
            templateId: strategyTemplate.template.identifier,
            templateApyInitialized: false
        }));
        setQuoteCollateral(collateral);
    };
}

export function useLendTemplateDetails() {
    const { form, templates } = useMarketLendContext();

    const template = useMemo(
        () => templates?.find((t) => t.template.identifier === form.templateId),
        [form.templateId, templates]
    );

    const maxDuration = useMemo(() => {
        if (!template) return undefined;
        return getMaxDurationForTemplate(template);
    }, [template]);

    return { template, maxDuration };
}

export function useLendMarketRates() {
    const { principalMint, durationToMinApy } = useMarketContext();
    const {
        presets,
        form: { presetToApy }
    } = useMarketLendContext();
    const { data: rates, isLoading: queryLoading } = useRatesHistoryQuery(
        { principalMints: principalMint ? [principalMint] : [], timeLookback: TIME.DAY },
        { skip: !principalMint }
    );
    const idleApy = useExternalYieldVaults().getYieldVault(principalMint)?.apy ?? 0;

    const isLoading = queryLoading || !durationToMinApy;
    const externalRates = rates?.[RateMarketType.KaminoMain];

    const externalRate = useMemo(() => {
        if (!externalRates) return undefined;
        let totalBorrow = 0;
        let totalLend = 0;
        for (const rate of externalRates) {
            totalBorrow += percentUiToDecimals(rate.borrowApy);
            totalLend += percentUiToDecimals(rate.lendApy);
        }
        const averageBorrow = totalBorrow / externalRates.length;
        const averageLend = totalLend / externalRates.length;

        const averageRate = (averageBorrow + averageLend) / 2;
        return { averageRate, averageBorrow, averageLend };
    }, [externalRates]);

    const marketMinApy = useMemo(() => {
        const selectedDurations = presets?.filter((p) => presetToApy.has(p.presetStrategyIdentifier));
        const apyRange = durationToMinApy
            ?.filter((bestOffer) => selectedDurations?.some((selected) => isStrategyDurationSame(selected, bestOffer)))
            .map((o) => o?.apy ?? 0);
        const marketMinApy = apyRange?.length ? Math.min(...apyRange) : undefined;
        return marketMinApy;
    }, [durationToMinApy, presetToApy, presets]);

    const defaultRate = useMemo(() => {
        if (isLoading) return undefined;

        // Ignore if lend > borrow rate (due to farming rewards)
        if (externalRate && externalRate.averageBorrow > externalRate.averageLend) {
            return Math.max(externalRate.averageRate, idleApy);
        }
        if (!marketMinApy) {
            return undefined;
        }
        return Math.max(idleApy, marketMinApy);
    }, [externalRate, isLoading, marketMinApy, idleApy]);

    return { defaultRate, externalRate, marketMinApy, isLoading };
}
