import { useEffect, useState } from "react";

import {
    Checkbox,
    Column,
    DialogWrapper,
    MAX_DIALOG_HEIGHT,
    OutlinedOption,
    Row,
    SearchInput,
    SkeletonRounded,
    StaticGrid,
    Text,
    TextButton,
    ToggleSpacedButtons,
    VerticalScroll,
    repeatElement,
    useAppPalette
} from "@bridgesplit/ui";
import {
    BsMetaUtil,
    getCollateralSourceData,
    getSearchFromMetadata,
    LabelVisibility,
    LoopscalePointSource,
    useCollateralPointsSourcesUtil
} from "@bridgesplit/abf-react";
import { MISSING_PARAM_ERROR, Result, deepCompareSets, formatPercentArray, textContains } from "@bridgesplit/utils";
import { Divider } from "@mui/material";
import { DispatchType } from "@bridgesplit/react";
import { AppDialog } from "app/utils";

import MarketDialogHeader from "./common";
import { useCollateralTokensByTag, useMarketDialog } from "../util";
import { AppButton, OverlappingMetadataImages, TokenImage } from "../../common";
import { CollateralLabel } from "../../points";

export default function MultiSelectCollateralDialog() {
    return (
        <DialogWrapper>
            <MultiSelectCollateralInternal />
        </DialogWrapper>
    );
}

enum Mode {
    Auto = "Categories",
    Advanced = "Custom"
}

export function MultiSelectCollateralInternal() {
    const { close, getData } = useMarketDialog();

    const data = getData(AppDialog.MultiSelectCollateral);

    const [selected, setSelected] = useState<Set<string>>();
    const [modeState, setMode] = useState<Mode>();
    const defaultMode = data?.isAdvanced ? Mode.Advanced : Mode.Auto;
    const mode = modeState === undefined ? defaultMode : modeState;

    useEffect(() => {
        if (!selected && data?.collateral) {
            setSelected(new Set(data.collateral));
        }
    }, [data?.collateral, selected]);

    const collateralByTag = useCollateralTokensByTag(data?.tokens);
    const { tokensByTags, allCollateralWithPresets } = collateralByTag;

    const props = { collateralByTag, selected, setSelected };

    function submit() {
        if (!allCollateralWithPresets || !data?.setCollateral) return;

        data?.setCollateral?.(allCollateralWithPresets.filter((t) => selected?.has(t.metadata.assetMint)));
        close();
    }

    async function onClickWithResult() {
        if (!allCollateralWithPresets || !data?.setCollateralAsync) return Result.errFromMessage(MISSING_PARAM_ERROR);

        return data.setCollateralAsync(allCollateralWithPresets.filter((t) => selected?.has(t.metadata.assetMint)));
    }

    const changesMade = !deepCompareSets(selected ?? new Set<string>(), new Set(data?.collateral));

    return (
        <>
            <MarketDialogHeader header={data?.header ?? "Select collateral"} />

            <ToggleSpacedButtons
                size="small"
                value={mode}
                setValue={setMode}
                options={Object.values(Mode).map((value) => ({ value }))}
            />

            {mode === Mode.Advanced ? <CustomSelection {...props} /> : <AutoSelection {...props} />}
            <Column spacing={1}>
                <Row spaceBetween>
                    <Text color="caption">{selected?.size ?? 0} tokens selected</Text>
                    <Row spacing={1}>
                        <TextButton color="caption" onClick={() => setSelected(new Set())}>
                            Clear
                        </TextButton>
                        <TextButton
                            color="secondary"
                            onClick={() =>
                                setSelected(new Set(allCollateralWithPresets?.map((c) => c.metadata.assetMint)))
                            }
                        >
                            All
                        </TextButton>
                    </Row>
                </Row>
                {!tokensByTags && <Column spacing={1}> {repeatElement(<SkeletonRounded height="20px" />, 3)}</Column>}

                <AppButton
                    isTransaction={false}
                    asyncCta={
                        data?.setCollateralAsync
                            ? {
                                  onClickWithResult: onClickWithResult
                              }
                            : undefined
                    }
                    disabled={!changesMade || !selected?.size}
                    variant="contained"
                    onClick={submit}
                >
                    {(() => {
                        if (!changesMade) return "No changes made";
                        if (!selected?.size) return "Select collateral";
                        return "Confirm selection";
                    })()}
                </AppButton>
            </Column>
        </>
    );
}

type CollateralByTagResponse = ReturnType<typeof useCollateralTokensByTag>;

type TokenSelectProps = {
    collateralByTag: CollateralByTagResponse;
    selected: Set<string> | undefined;
    setSelected: DispatchType<Set<string> | undefined>;
};

function CustomSelection({ collateralByTag: { tokensByTags }, selected, setSelected }: TokenSelectProps) {
    const { hoverBackground } = useAppPalette();
    const [search, setSearch] = useState("");

    const { hasPointSource } = useCollateralPointsSourcesUtil();

    const filtered = tokensByTags
        ?.map(({ tag, tokens }) => {
            const filteredTokens = tokens.filter(({ metadata }) => {
                if (metadata.assetMint === search) return true;
                return textContains(getSearchFromMetadata(metadata), search);
            });

            return { tag, tokens: filteredTokens };
        })
        .filter(({ tokens }) => tokens.length);

    return (
        <>
            <SearchInput
                placeholder="Search by token or address"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
            />
            <VerticalScroll sx={{ mx: -2 }} spacing={2} maxHeight={MAX_DIALOG_HEIGHT}>
                {filtered?.map(({ tokens, tag }, i) => {
                    const selectedTokens = tokens.filter((t) => selected?.has(t.metadata.assetMint));
                    const allSelected = selectedTokens.length === tokens.length;
                    return (
                        <Column key={i} spacing={1}>
                            <Row sx={{ px: 2 }} spaceBetween>
                                <Text color="caption">{tag.label}</Text>
                                <Checkbox
                                    indeterminate={!allSelected && !!selectedTokens.length}
                                    checked={allSelected}
                                    onChange={() => {
                                        setSelected((prev) => {
                                            const dupe = new Set(prev);
                                            for (const token of tokens) {
                                                const key = token.metadata.assetMint;
                                                if (selectedTokens.length) {
                                                    dupe.delete(key);
                                                } else {
                                                    dupe.add(key);
                                                }
                                            }

                                            return dupe;
                                        });
                                    }}
                                />
                            </Row>

                            <Divider />
                            <Column>
                                {tokens.map(({ metadata, presets }, i) => {
                                    const { eligibleMultipliers, showRewards } = getCollateralSourceData(
                                        metadata,
                                        hasPointSource,
                                        [LoopscalePointSource.Lend, LoopscalePointSource.IdleCap]
                                    );
                                    const isSelected = selected?.has(metadata.assetMint) || false;

                                    return (
                                        <Row
                                            key={i}
                                            onClick={() =>
                                                setSelected((prev) => {
                                                    const dupe = new Set(prev);
                                                    if (dupe.has(metadata.assetMint)) {
                                                        dupe.delete(metadata.assetMint);
                                                    } else {
                                                        dupe.add(metadata.assetMint);
                                                    }

                                                    return dupe;
                                                })
                                            }
                                            spaceBetween
                                            sx={{
                                                px: 2,
                                                py: 0.5,
                                                cursor: "pointer",
                                                ":hover": { background: hoverBackground }
                                            }}
                                        >
                                            <Row spacing={1}>
                                                <TokenImage metadata={metadata} size="md" />

                                                <Column>
                                                    <Row spacing={1}>
                                                        <Text> {BsMetaUtil.getSymbolUnique(metadata)} </Text>
                                                        <CollateralLabel
                                                            metadata={metadata}
                                                            loopscalePointSources={eligibleMultipliers}
                                                            visibility={
                                                                showRewards
                                                                    ? LabelVisibility.Hidden
                                                                    : LabelVisibility.ShowFull
                                                            }
                                                            showSymbolLabel={false}
                                                        />
                                                    </Row>
                                                    <Text variant="body2" color="caption">
                                                        {formatPercentArray(presets.map((p) => p.offerTerms.ltv))} LTV
                                                    </Text>
                                                </Column>
                                            </Row>
                                            <Checkbox checked={!!isSelected} />
                                        </Row>
                                    );
                                })}
                            </Column>
                        </Column>
                    );
                })}
            </VerticalScroll>
        </>
    );
}

function AutoSelection({ collateralByTag: { tokensByTags }, selected, setSelected }: TokenSelectProps) {
    return (
        <StaticGrid gridGap={1} mobileColumns={2} columns={2}>
            {tokensByTags?.map(({ tag: { tag, shortLabel }, tokens }) => {
                const isSelected = tokens.every((t) => selected?.has(t.metadata.assetMint));

                function onClick() {
                    setSelected((prev) => {
                        const copy = new Set(prev);

                        for (const token of tokens) {
                            if (copy.has(token.metadata.assetMint)) {
                                copy.delete(token.metadata.assetMint);
                            } else {
                                copy.add(token.metadata.assetMint);
                            }
                        }

                        return copy;
                    });
                }

                return (
                    <OutlinedOption onClick={onClick} padding={0} isSelected={isSelected} key={tag}>
                        <Column spacing={1} p={1}>
                            {tag && <Text color={isSelected ? "body" : "caption"}>{shortLabel}</Text>}

                            <OverlappingMetadataImages
                                maxLength={3}
                                plusVariant="caption"
                                size="20px"
                                metadata={tokens.map((t) => t.metadata)}
                            />
                        </Column>
                    </OutlinedOption>
                );
            })}
        </StaticGrid>
    );
}
