import { DialogWrapper } from "@bridgesplit/ui";
import { AppDialog, useAppDialog } from "app/utils";

import DepositWithSource from "./deposit/DepositWithSource";
import { WalletRequiredWrapper } from "../transactions";
import { SelectChain } from "../wormhole";
import { EvmBalanceWrapperWithSwitch } from "./common";
import { DialogHeaderWithBack } from "../common";

export default function DepositDialog() {
    const { getData } = useAppDialog();

    const data = getData(AppDialog.Deposit);

    return (
        <DialogWrapper>
            <SelectChain.Wrapper>
                <EvmBalanceWrapperWithSwitch>
                    <WalletRequiredWrapper>
                        <DialogHeaderWithBack
                            header="Deposit"
                            rowEnd={<SelectChain.PromptToSwitch />}
                            back={data?.back}
                        />

                        <DepositWithSource
                            specifiedCustodian={data?.specifiedCustodian}
                            specifiedKeys={data?.specifiedAssetKeys}
                        />
                    </WalletRequiredWrapper>
                </EvmBalanceWrapperWithSwitch>
            </SelectChain.Wrapper>
        </DialogWrapper>
    );
}
