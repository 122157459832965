import { useState } from "react";

import { Button, DialogWrapper, ErrorWrapper, toBase64 } from "@bridgesplit/ui";
import {
    usePageLoadIdempotentKey,
    useUploadSphereIncorporationDocumentationMutation,
    useUploadSphereUboDocumentationMutation
} from "@bridgesplit/abf-react";
import { Result, LOADING_ERROR, formatBytes, getReadableErrorMessage } from "@bridgesplit/utils";
import { FileExtensionEnum, RawFile, useAsyncResultHandler } from "@bridgesplit/react";
import { AppDialog, useAppDialog } from "app/utils";

import { DialogHeader, FileUpload } from "../../common";
import { useSphereRefresh } from "../util";

const MAX_SIZE = 1 * 1024 ** 2;
export default function UploadSphereDocumentDialog() {
    const [file, setFile] = useState<File>();
    const { close, getData } = useAppDialog();
    const type = getData(AppDialog.UploadSphereDocument)?.type;

    const [uploadIncorporation] = useUploadSphereIncorporationDocumentationMutation();
    const [uploadUbo] = useUploadSphereUboDocumentationMutation();
    const getKey = usePageLoadIdempotentKey();

    const { resultHandler, errorMessage, isLoading } = useAsyncResultHandler();
    const refresh = useSphereRefresh();

    async function submit() {
        if (!file) return Result.errFromMessage(LOADING_ERROR);
        if (file.size > MAX_SIZE)
            return Result.errFromMessage(
                `Your file (${formatBytes(file.size)}) exceeds ${formatBytes(MAX_SIZE)} file size limit`
            );
        try {
            const b64 = await toBase64(file);
            const encoded = b64?.toString().split(",").pop();

            if (!encoded) {
                return Result.errFromMessage("Unable to encode file");
            }

            const param: RawFile = {
                fileContents: encoded,
                fileExtension: FileExtensionEnum.Pdf
            };

            const upload = type === "incorporation" ? uploadIncorporation : uploadUbo;
            const res = await upload({ ...param, idempotentKey: getKey(file.name) });

            if ("error" in res) return Result.errWithDebug(getReadableErrorMessage("upload your file"), res);
            close();
            await refresh({ kyb: true });
            return Result.ok();
        } catch (error) {
            return Result.errWithDebug(getReadableErrorMessage("upload files"), error);
        }
    }

    return (
        <DialogWrapper>
            <DialogHeader
                header={type === "ubo" ? "Upload UBO Docs" : "Upload Formation Docs"}
                description={`Upload corporate documentation for either of your ${
                    type === "ubo"
                        ? "Shareholder register, Membership Interest/Share Purchase/Transfer Agreement, or Bylaws"
                        : "Articles of Incorporation, Articles of Association, or Statement of Information"
                }`}
            />
            <FileUpload
                acceptedFiles={[FileExtensionEnum.Pdf]}
                file={file}
                setFile={setFile}
                maxFileSizeBytes={MAX_SIZE}
            />

            <ErrorWrapper errorMessage={errorMessage}>
                <Button
                    disabled={!file}
                    loading={isLoading}
                    onClick={() =>
                        resultHandler(submit, { onSuccessMessage: "Document uploaded. Please check back soon" })
                    }
                    variant="contained"
                >
                    Upload
                </Button>
            </ErrorWrapper>
        </DialogWrapper>
    );
}
