import { TIME } from "../constants/time";

export function convertDateToSeconds(d: Date) {
    return Math.floor(d.getTime() / 1000);
}

export const getUnixTs = () => {
    return Math.round(new Date().getTime() / 1000);
};

export function sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function unixTime(seconds: number) {
    const now = Math.round(getUnixTs());
    const afterNow = now > seconds;
    const beforeNow = now < seconds;
    return { afterNow, beforeNow };
}

export function formatSeconds(seconds: number | undefined, options?: { maxUnit?: "day" }) {
    const getStr = (val: number, unit: string) => {
        const timeAsStr = val.toFixed(0);
        const plural = timeAsStr === "1" ? "" : "s";
        return `${timeAsStr} ${unit}${plural}`;
    };
    if (!seconds || seconds < TIME.MINUTE) {
        return "≤1 minute";
    }
    if (seconds < TIME.HOUR) {
        return getStr(seconds / TIME.MINUTE, "minute");
    }
    if (seconds < TIME.DAY) {
        return getStr(seconds / TIME.HOUR, "hour");
    }
    if (seconds < TIME.WEEK || options?.maxUnit === "day") {
        return getStr(seconds / TIME.DAY, "day");
    }
    if (seconds < TIME.MONTH) {
        return getStr(seconds / TIME.WEEK, "week");
    }
    if (seconds < TIME.YEAR) {
        return getStr(seconds / TIME.MONTH, "month");
    }
    return getStr(seconds / TIME.YEAR, "year");
}

const MAX_UNIX_TIMESTAMP = new Date("01/01/2040").getTime() / 1000;
type Unix = number;
type AnyDate = Date | Unix | string | undefined;
export function convertAnyDate(d: AnyDate) {
    if (d === undefined) return new Date();
    if (typeof d === "number") {
        if (d > MAX_UNIX_TIMESTAMP) return new Date(d);
        return new Date(d * 1000);
    }
    if (typeof d === "string") return new Date(d);
    return d;
}

export function isPastNow(d: AnyDate) {
    return Date.now() > convertAnyDate(d).getTime();
}

export function isBeforeNow(d: AnyDate) {
    return Date.now() < convertAnyDate(d).getTime();
}

export function formatDate(
    d: Date | Unix | string | undefined,
    format: "relative" | "date" | "time" | "both" = "both",
    options?: { alwaysShowYear?: boolean; showTime?: "auto" | "never" | "always" }
) {
    if (d === undefined || d === 0) return "--";

    const date = convertAnyDate(d);
    if (format === "relative") {
        const now = Math.round(getUnixTs());
        const time = convertDateToSeconds(date);
        if (now > time) {
            return `${formatSeconds(now - time)} ago`;
        }
        return `In ${formatSeconds(time - now)}`;
    }
    if (format === "date") {
        const showTimeSetting = options?.showTime ?? "auto";
        const showYear = options?.alwaysShowYear || date.getFullYear() !== new Date().getFullYear();
        const showTime =
            showTimeSetting === "always" ||
            (showTimeSetting === "auto" && Math.abs(date.getTime() - Date.now()) < TIME.DAY * 1000);

        return date.toLocaleString([], {
            month: "short",
            day: "2-digit",
            hour: showTime ? "numeric" : undefined,
            minute: showTime ? "numeric" : undefined,
            year: showYear ? "numeric" : undefined
        });
    }
    if (format === "time") {
        return date.toLocaleString([], { timeStyle: "short" });
    }
    return date.toLocaleString([], { month: "short", day: "2-digit", minute: "numeric", hour: "numeric" });
}
