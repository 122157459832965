import React, { ReactNode, useEffect } from "react";

import { useAlert, useAlertState } from "@bridgesplit/react";
import { AlertsSnackbar, TransactionsResultsSnackbar } from "@bridgesplit/ui";
import { CustomContentProps, SnackbarContent, SnackbarProvider } from "notistack";

export default function AppAlerts({ children }: { children: ReactNode }) {
    return (
        <SnackbarProvider
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            maxSnack={6}
            Components={{ default: Snackbar, error: Snackbar, success: Snackbar, warning: Snackbar, info: Snackbar }}
        >
            {children}
        </SnackbarProvider>
    );
}

const Snackbar = React.forwardRef<HTMLDivElement, CustomContentProps>((props, ref) => {
    return (
        <SnackbarContent ref={ref}>
            <SnackbarForKey id={props.message as string} />
        </SnackbarContent>
    );
});

function SnackbarForKey({ id }: { id: string }) {
    const { alerts } = useAlertState();
    const { dismiss } = useAlert();

    const alert = alerts[id];

    // dismiss modal
    useEffect(() => {
        if (!alert || alert.variant === "spinner") return;
        const timer = setTimeout(
            () => {
                dismiss(id);
            },
            alert.transactionsResults ? 5_000 : 3_500
        );

        return () => clearTimeout(timer);
    }, [alert, dismiss, id]);

    if (!alert) return null;

    const { variant, message, description, transactionsResults, progress } = alert;

    if (transactionsResults.length) {
        return (
            <TransactionsResultsSnackbar
                close={() => dismiss(id)}
                results={transactionsResults.map((r) => ({ ...r, title: r.identifier }))}
                message={message}
                description={description}
                variant={variant}
            />
        );
    }

    return (
        <AlertsSnackbar
            message={message}
            description={description}
            variant={variant}
            progress={progress}
            close={() => dismiss(id)}
        />
    );
}
