import { useState } from "react";

import { EvmTransactionType, useEstimateGas } from "@bridgesplit/abf-react";
import { Column, Row, OverlappingImages, Text, EmptyPlaceholder } from "@bridgesplit/ui";
import { ChainId } from "@bridgesplit/abf-sdk";
import { formatAddress } from "@bridgesplit/utils";
import { COPY } from "app/constants";

import { ChainAddressInput, isValidAddress } from "../../common";
import { useTransferFromMpcToEvm, EvmBalance } from "../../wormhole";
import { useUserEvmNfts } from "../util";
import { describeAssetTransfer } from "../common";

export default function EvmWithdrawToWallet({ chain, selected }: { chain: ChainId; selected: Set<string> }) {
    const { data: nfts } = useUserEvmNfts(chain);

    const selectedAssets = nfts?.filter((n) => selected.has(n.solanaMint));

    const transfer = useTransferFromMpcToEvm();
    const [address, setAddress] = useState<string>();
    const gasTransactions = Array<EvmTransactionType>(selected.size).fill(EvmTransactionType.TransferNft);

    const estimatedGas = useEstimateGas(chain)(gasTransactions);
    const assetDescription = describeAssetTransfer(selectedAssets);

    if (selectedAssets?.length === 0) {
        return (
            <EmptyPlaceholder header="Unable to retry" description="This asset is no longer available to withdraw" />
        );
    }

    return (
        <>
            <ChainAddressInput chainId={chain} value={address} setValue={setAddress} />

            <Column spacing={1}>
                <Row spaceBetween>
                    <Text color="caption">Transfer </Text>
                    <Row spacing={1}>
                        <OverlappingImages size="20px" images={selectedAssets?.map((i) => i.image)} />
                        <Text>
                            {selectedAssets?.length === 1
                                ? selectedAssets[0].name
                                : `${selectedAssets?.length ?? 0} Assets`}
                        </Text>
                    </Row>
                </Row>
                <EvmBalance.Cta
                    isTransaction={false}
                    requestedBalance={estimatedGas?.total}
                    disabled={!selectedAssets?.length || !address || !isValidAddress(chain, address)}
                    asyncCta={{
                        onClickWithResult: () =>
                            transfer({
                                recipientAddress: address ?? "",
                                chainId: chain,
                                nfts: selectedAssets ?? []
                            }),
                        options: {
                            loadingMessage: {
                                message: `Sending ${assetDescription} to ${formatAddress(address)}`,
                                description: COPY.ASSET_BRIDGE_TRANSFER_LENGTH
                            },
                            alertOnError: true,
                            onSuccessMessage: `Sent ${assetDescription} to ${formatAddress(address)}`
                        }
                    }}
                >
                    Transfer to Wallet
                </EvmBalance.Cta>
            </Column>
        </>
    );
}
