import { useState } from "react";

import { BsMetaUtil, LoopPositionExpanded } from "@bridgesplit/abf-react";
import { Row, ShadowCard, Column, Text, ToggleChevron, MediaQuery } from "@bridgesplit/ui";
import { Collapse, useMediaQuery } from "@mui/material";
import { bsMath, formatUsd } from "@bridgesplit/utils";

import { LoopImage } from "../../common";
import LoopPositionStats from "./LoopPositionStats";
import { LoanActions } from "../../loan";
import { LoopPositionApy } from "./common";

export default function LoopPositionCard({
    loopPosition,
    query
}: {
    loopPosition: LoopPositionExpanded | undefined;
    query: MediaQuery;
}) {
    const [expandedState, setExpanded] = useState(false);

    const loopExpanded = loopPosition?.loopExpanded;
    const userLoopInfo = loopPosition?.userLoopInfo;
    const isMobile = useMediaQuery(query.below);

    const expanded = expandedState || isMobile;

    const collateralAmount = bsMath.div(userLoopInfo?.netPositionValue, loopExpanded?.collateralOracle.usdPrice);

    return (
        <ShadowCard sx={{ flexGrow: 1, overflow: "visible" }} padding={false}>
            <Row
                sx={{ p: 2, cursor: loopPosition ? "pointer" : undefined }}
                onClick={loopPosition ? () => setExpanded((prev) => !prev) : undefined}
                spaceBetween
            >
                <Row spacing={1}>
                    <LoopImage size="md" badgeSize="xs" loopExpanded={loopExpanded} />
                    <Text loadingWidth="100px" loading={!loopPosition}>
                        {BsMetaUtil.formatAmount(loopExpanded?.collateralToken, collateralAmount)}
                    </Text>
                    <Text loadingWidth="55px" loading={!loopPosition} color="caption">
                        {formatUsd(loopExpanded?.collateralOracle.getUsdAmount(collateralAmount))}
                    </Text>
                </Row>

                <Row sx={{ [query.below]: { display: "none" } }} spacing={1}>
                    {userLoopInfo?.netApy !== null ? (
                        <LoopPositionApy loopPosition={loopPosition} />
                    ) : (
                        <Text color="disabled"> Closed </Text>
                    )}
                    <ToggleChevron disabled={!loopPosition} textVariant="h2" expanded={expanded} />
                </Row>
            </Row>

            <Collapse in={expanded}>
                <Column sx={{ p: 2, pt: 0 }} spacing={2}>
                    <LoopPositionStats loopPosition={loopPosition} query={query} isExpanded={expanded} />
                    <LoanActions
                        maxItems={1}
                        loanExpanded={loopPosition?.loanExpanded}
                        loopPosition={loopPosition}
                        linkToDetailCta="View loan"
                        isCondensed={isMobile}
                    />
                </Column>
            </Collapse>
        </ShadowCard>
    );
}
