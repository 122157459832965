import { useCallback } from "react";

import { DialogWrapper } from "@bridgesplit/ui";
import { MarketGuideMode, OnboardingStep, useMarkOnboardingStepComplete } from "@bridgesplit/abf-react";
import { AppDialog } from "app/utils";
import { COPY, DOCS } from "app/constants";
import { TrackEvent } from "app/types";

import { LEND_BULLETS, BORROW_BULLETS } from "./constants";
import RoleGuide from "./RoleGuide";
import { useMarketDialog } from "../util";
import MultiRoleGuide from "./MultiRoleGuide";
import { generateMarketGuideBullets } from "./util";

export default function MarketOnboardingDialog() {
    const { getData } = useMarketDialog();
    const data = getData(AppDialog.MarketGuide);

    return (
        <DialogWrapper preventClose={data?.isForced}>
            <MarketOnboardingDialogInternal />
        </DialogWrapper>
    );
}

export function MarketOnboardingDialogInternal() {
    const { getData, close } = useMarketDialog();
    const data = getData(AppDialog.MarketGuide);

    const { markAsComplete } = useMarkOnboardingStepComplete();

    const mode = data?.mode;

    const next = useCallback(
        (step: OnboardingStep) => {
            close();

            // always update the onboarding step
            if (data?.isForced) {
                markAsComplete([step]);
            }
        },
        [close, data?.isForced, markAsComplete]
    );

    switch (mode) {
        case MarketGuideMode.Borrow:
            return (
                <RoleGuide
                    isForced={!!data?.isForced}
                    isNested={!!data?.isNestedDialog}
                    next={next}
                    forcedTitle="The new way to borrow"
                    unforcedTitle="How borrowing works on Loopscale"
                    step={OnboardingStep.Borrow}
                    bullets={BORROW_BULLETS}
                    docs={DOCS.borrowTutorial}
                    trackingEvent={TrackEvent.SubmitMarketGuideBorrow}
                />
            );
        case MarketGuideMode.Lend:
            return (
                <RoleGuide
                    isForced={!!data?.isForced}
                    isNested={!!data?.isNestedDialog}
                    forcedTitle="The new way to lend"
                    unforcedTitle="How lending works on Loopscale"
                    step={OnboardingStep.Lend}
                    bullets={LEND_BULLETS}
                    docs={DOCS.lendTutorial}
                    next={next}
                    trackingEvent={TrackEvent.SubmitMarketGuideLend}
                />
            );
        case MarketGuideMode.BorrowAndLend:
            return (
                <MultiRoleGuide
                    title="How it works"
                    close={close}
                    isNested={!!data?.isNestedDialog}
                    sections={[
                        { label: "Borrowing", bullets: BORROW_BULLETS, docs: DOCS.borrowTutorial },
                        { label: "Lending", bullets: LEND_BULLETS, docs: DOCS.lendTutorial }
                    ]}
                />
            );
        case MarketGuideMode.Earn:
            return (
                <RoleGuide
                    isForced={!!data?.isForced}
                    isNested={!!data?.isNestedDialog}
                    next={next}
                    forcedTitle="How this vault works"
                    unforcedTitle={`How ${COPY.LOOP_TERM_PLURAL.toLocaleLowerCase()} works on Loopscale`}
                    step={OnboardingStep.Earn}
                    bullets={generateMarketGuideBullets(data?.loopExpanded)}
                    docs={DOCS.borrowTutorial}
                    trackingEvent={TrackEvent.SubmitMarketGuideLoops}
                />
            );
        default:
            return null;
    }
}
