import {
    AbfFeeAccount,
    AbfLedgerAccount,
    AbfLoanVault,
    AbfOrder,
    AbfOrderSchedule,
    AbfOrderStatus,
    CompoundingFrequency,
    DurationDetails,
    RepaymentType,
    formatDurationWithType,
    getDurationInSeconds,
    getRRuleFrequencyDurationDetails
} from "@bridgesplit/abf-sdk";
import { rrulestr, RRuleSet } from "rrule";
import { PublicKey } from "@solana/web3.js";
import {
    ABF_PID,
    DEFAULT_PUBLIC_KEY,
    TIME,
    formatNum,
    getUnixTs,
    formatTokenAmount,
    findMinElement
} from "@bridgesplit/utils";

import { calculateApy } from "./math";
import { AbfLoanExpanded, AbfOrderExpanded, AbfUiLoanType } from "../types";
import { getContinuousCompoundedInterest, getPaymentScheduleFromDates, getTimeOffsetsFromRRuleSet } from "./rrule";
import { utfEncodeString } from "./pda";
import { isLoanZeroCoupon } from "./loan";

export function getLoanTypeFromOrder(rruleStr: string | undefined): AbfUiLoanType {
    const rruleSet = getRruleFromString(rruleStr);
    if (!rruleSet) return AbfUiLoanType.ZeroCoupon;

    const dates = rruleSet.all();

    if (dates.length === 1) {
        return AbfUiLoanType.ZeroCoupon;
    }
    return AbfUiLoanType.PeriodicRepayments;
}

const FALLBACK = "Single repayment";
export function getRepaymentFrequency(rruleStr: string | undefined): string {
    const rruleSet = getRruleFromString(rruleStr);
    if (!rruleSet) return FALLBACK;

    const info = parseRruleRepaymentsInfo(rruleSet);

    if (!info || info.interval === 0) return FALLBACK;
    return formatDurationByInterval(info.interval, info.details);
}

export function parseRruleRepaymentsInfo(rruleSet: RRuleSet | undefined) {
    if (!rruleSet) return undefined;
    const [rule] = rruleSet._rrule;
    if (!rule) return undefined;
    const freq = rule.options.freq;
    const interval = rule.options.interval;
    const details = getRRuleFrequencyDurationDetails(freq);

    return { interval, frequencyUnit: details.type, details, options: rule.origOptions };
}

export function formatRepayments({
    rruleStr,
    principalAmount = 0,
    apy = 0,
    symbol
}: {
    rruleStr: string | undefined;
    principalAmount: number | undefined;
    apy: number | undefined;
    symbol: string;
}) {
    const rruleSet = getRruleFromString(rruleStr);
    if (!rruleSet) return FALLBACK;
    const timeOffsets = getTimeOffsetsFromRRuleSet(rruleSet);
    const { schedule, totalInterest } = getPaymentScheduleFromDates({
        timeOffsets,
        apy,
        principal: principalAmount
    });

    const intervalInterest = totalInterest / Math.max(schedule.length - 1, 1);

    if (isNaN(intervalInterest)) {
        return getRepaymentFrequency(rruleStr);
    }
    const interestFormatted = formatTokenAmount(intervalInterest, { symbol });

    return `${interestFormatted} ${getRepaymentFrequency(rruleStr).toLocaleLowerCase()}`;
}

export function formatDurationByInterval(interval = 1, details: DurationDetails) {
    if (interval === 1) {
        return details?.unitSingle;
    }

    return `Every ${formatNum(interval)} ${details.unit.toLocaleLowerCase()}s`;
}

export function getOfferDuration(orderSchedule: AbfOrderSchedule | undefined): string {
    return formatDurationWithType(orderSchedule?.duration, orderSchedule?.durationType);
}

export function getRruleFromString(rruleStr: string | undefined) {
    try {
        if (!rruleStr) return undefined;
        return rrulestr(rruleStr, { forceset: true }) as RRuleSet;
    } catch (error) {
        return undefined;
    }
}

// Calculate the "real" loan APY from ledgers. Might slightly differ from order schedule due to rounding
export function calculateLoanApy(loanInfo: AbfLoanExpanded | undefined) {
    if (!loanInfo) return undefined;

    const { principalDue, interestDue, lengthSeconds } = summarizeLedgerAccounts(loanInfo);
    const principalAmount = loanInfo?.principalAmount ?? principalDue;

    return calculateApy({
        yearInSeconds: getLoanYearConstant(loanInfo.loan),
        principalAmount,
        repaymentAmount: principalAmount + interestDue,
        durationInSeconds: lengthSeconds
    });
}
/**
 * Previously, loan duration used an incorrect constant for YEAR in seconds,
 * which resulted in a minor difference for calculating ledgers from a schedule
 * @returns the seconds in a year used for a loan's APY calc
 */
export function getLoanYearConstant(loan: AbfLoanVault) {
    if (loan.loanStartTime > new Date("2/19/2024").getTime() / 1000) return TIME.YEAR;
    return 31556925.9936;
}

export function summarizeFeeAccounts(feeAccounts: AbfFeeAccount[] | undefined = []) {
    const feesPaid = feeAccounts.reduce((prev, curr) => prev + curr.amountPaid, 0);
    const feesDue = feeAccounts.reduce((prev, curr) => prev + curr.amountDue, 0);
    const feesOutstanding = Math.max(feesDue - feesPaid, 0);

    return { feesPaid, feesDue, feesOutstanding };
}

export function summarizeLedgerAccounts(
    loanExpanded: AbfLoanExpanded | undefined,
    customLedgerAccounts?: AbfLedgerAccount[]
) {
    const ledgerAccounts = customLedgerAccounts ?? loanExpanded?.ledgerAccounts;
    const customLedgerIds = new Set(customLedgerAccounts?.map((l) => l.ledgerId));
    const feeAccounts = customLedgerAccounts
        ? loanExpanded?.feeAccounts.filter((f) => customLedgerIds.has(f.ledgerId))
        : loanExpanded?.feeAccounts;

    const lengthSeconds = ledgerAccounts ? Math.max(...ledgerAccounts.map((e) => e.dueTimeOffset)) : 0;

    const principalDue =
        ledgerAccounts?.reduce((prev, curr) => {
            // handle overpaid principal
            return prev + Math.max(curr.principalDue, curr.principalPaid);
        }, 0) || 0;
    const principalPaid = ledgerAccounts?.reduce((prev, curr) => prev + curr.principalPaid, 0) || 0;
    const principalOutstanding = Math.max(principalDue - principalPaid, 0);

    const interestDue = ledgerAccounts?.reduce((prev, curr) => prev + curr.interestDue, 0) || 0;
    const interestPaid = ledgerAccounts?.reduce((prev, curr) => prev + curr.interestPaid, 0) || 0;
    const interestOutstanding = Math.max(interestDue - interestPaid, 0);

    const { feesOutstanding, feesPaid, feesDue } = summarizeFeeAccounts(feeAccounts);

    const totalDue = interestDue + principalDue + feesDue;
    const totalPaid = principalPaid + interestPaid + feesPaid;
    const totalOutstandingWithoutFees = principalOutstanding + interestOutstanding;
    const totalOutstanding = totalOutstandingWithoutFees + feesOutstanding;

    const overPaidPrincipal = Math.max(principalPaid - principalDue, 0);

    return {
        interestDue,
        principalDue,
        interestPaid,
        principalPaid,
        totalDue,
        lengthSeconds,
        totalPaid,
        totalOutstanding,
        principalOutstanding,
        interestOutstanding,
        overPaidPrincipal,
        totalOutstandingWithoutFees,
        feesOutstanding
    };
}

export function getRefinancedInterestPaid(loanExpanded: AbfLoanExpanded) {
    if (!loanExpanded.loan.refinancedTo) return loanExpanded.debt.interestAccrued;

    if (!isLoanZeroCoupon(loanExpanded) || loanExpanded.ledgerAccounts.length !== 1)
        return summarizeLedgerAccounts(loanExpanded).interestPaid;

    const ledgerAccount = loanExpanded.ledgerAccounts[0];

    // if loan was refinanced early, all interest is marked as paid even though only a portion was paid
    const repaymentTimeOffset = ledgerAccount.lastInteractedTime
        ? Math.abs(ledgerAccount.lastInteractedTime - loanExpanded.loan.loanStartTime)
        : 0;

    if (repaymentTimeOffset >= ledgerAccount.dueTimeOffset) return ledgerAccount.interestPaid;

    return getContinuousCompoundedInterest({
        principal: ledgerAccount.principalDue,
        apy: loanExpanded.apy,
        loanDuration: repaymentTimeOffset,
        subtractPrincipal: true
    });
}

export function calculateInterestSavedIfPaidEarly({
    loanExpanded,
    repaymentAmount,
    repaymentType,
    interestOutstanding: interestOutstandingOverride,
    paymentTime = getUnixTs()
}: {
    loanExpanded: AbfLoanExpanded | undefined;
    repaymentAmount: number | undefined;
    repaymentType: RepaymentType;
    interestOutstanding?: number;
    paymentTime?: number;
}): number {
    if (!loanExpanded || !repaymentAmount) return 0;

    const {
        ledgerAccounts,
        loan: { loanStartTime },
        order: { finalPaymentTimeOffset },
        orderSchedule: { compoundingFrequency }
    } = loanExpanded;
    const earlyFees = loanExpanded?.feeSchedule?.earlyFees;

    const isCompoundingLoan =
        compoundingFrequency === undefined || compoundingFrequency !== CompoundingFrequency.Simple;

    let minimumTimeDelta = 0;

    if (earlyFees.length > 0) {
        const earliestFeeSchedule = findMinElement(earlyFees, (a) =>
            getDurationInSeconds(a.feeMetadata.duration, a.feeMetadata.durationType)
        );
        if (earliestFeeSchedule) {
            minimumTimeDelta = getDurationInSeconds(
                earliestFeeSchedule.feeMetadata.duration,
                earliestFeeSchedule.feeMetadata.durationType
            );
        }
    }

    const {
        principalOutstanding,
        interestOutstanding: ledgerInterestOutstanding,
        interestDue
    } = summarizeLedgerAccounts(loanExpanded);
    const interestOutstanding = interestOutstandingOverride ?? ledgerInterestOutstanding;

    const currentTimeMinusLoanStart = Math.max(paymentTime - loanStartTime, 0);

    const interestInterval = Math.max(currentTimeMinusLoanStart, minimumTimeDelta);

    // use original order apy always
    const apy = loanExpanded.orderSchedule.apy;

    if (!isCompoundingLoan) {
        const ledgerAccountsDueInPast = ledgerAccounts.filter((l) => paymentTime > l.dueTimeOffset + loanStartTime);
        const ledgerAccountsDueInFuture = ledgerAccounts.filter((l) => paymentTime <= l.dueTimeOffset + loanStartTime);
        const interestDueInPast = ledgerAccountsDueInPast.reduce((prev, curr) => prev + curr.interestDue, 0) || 0;
        const futureInterestPaid = ledgerAccountsDueInFuture.reduce((prev, curr) => prev + curr.interestPaid, 0) || 0;
        return calculateNewInterestSavedForSimpleLoan({
            interestDueInPast,
            interestInterval,
            repaymentAmount,
            repaymentType,
            apy,
            futureInterestPaid,
            interestDue,
            finalPaymentTimeOffset,
            principalOutstanding
        });
    }
    return calculateInterestSavedForContinuousLoan({
        loanDuration: loanExpanded.order.finalPaymentTimeOffset,
        interestInterval,
        repaymentAmount,
        apy,
        interestOutstanding,
        principalOutstanding,
        repaymentType
    });
}

function calculateNewInterestSavedForSimpleLoan({
    interestDueInPast,
    interestInterval,
    repaymentAmount,
    apy,
    principalOutstanding,
    finalPaymentTimeOffset,
    futureInterestPaid,
    interestDue,
    repaymentType
}: {
    interestDueInPast: number;
    interestInterval: number;
    futureInterestPaid: number;
    repaymentAmount: number;
    apy: number;
    principalOutstanding: number;
    finalPaymentTimeOffset: number;
    interestDue: number;
    repaymentType: RepaymentType;
}) {
    if (repaymentType !== RepaymentType.Principal) {
        return 0;
    }
    const newPrincipalOutstanding = Math.max(principalOutstanding - repaymentAmount, 0);

    const rate = apy / TIME.YEAR;

    // get interest due for principal outstanding from loan start until now
    const interestAccruedForRepaidPrincipal = repaymentAmount * rate * interestInterval;

    // get interest due for new principal outstanding from now until the loan is over
    const futureInterest = newPrincipalOutstanding * rate * finalPaymentTimeOffset;

    return Math.max(
        interestDue - (interestAccruedForRepaidPrincipal + futureInterest - interestDueInPast - futureInterestPaid),
        0
    );
}

function calculateInterestSavedForContinuousLoan({
    loanDuration,
    interestInterval,
    repaymentAmount,
    apy,
    interestOutstanding,
    principalOutstanding,
    repaymentType
}: {
    loanDuration: number;
    interestInterval: number;
    repaymentAmount: number;
    apy: number;
    interestOutstanding: number;
    principalOutstanding: number;
    repaymentType: RepaymentType;
}) {
    const timeBetweenPrepaymentAndMaturity = loanDuration - interestInterval;
    const ledgerRepaymentAmount =
        repaymentType === RepaymentType.Interest
            ? Math.min(repaymentAmount, interestOutstanding)
            : Math.min(repaymentAmount, principalOutstanding);

    const interestDueInFuture = getContinuousCompoundedInterest({
        principal: ledgerRepaymentAmount,
        apy,
        loanDuration: timeBetweenPrepaymentAndMaturity,
        yearConstant: TIME.YEAR,
        subtractPrincipal: true
    });

    const interestSaved = interestDueInFuture;

    return interestSaved;
}

export function isRepaymentInWindow(ledgerAccount: AbfLedgerAccount, loanEndtime: number): boolean {
    const timeUntilLoanEnd = loanEndtime - getUnixTs();
    if (ledgerAccount.paymentWindow && ledgerAccount.paymentWindow > timeUntilLoanEnd) {
        return true;
    } else {
        return false;
    }
}

export function isValidOffer({ order, requestMaker }: AbfOrderExpanded) {
    const designatedTaker = order.designatedTaker;
    const isOrderMadeToRequest =
        designatedTaker === DEFAULT_PUBLIC_KEY || designatedTaker === requestMaker.groupIdentifier;

    return isOrderMadeToRequest;
}

export function isPendingOffer({ order }: { order: AbfOrder }) {
    return [AbfOrderStatus.Active, AbfOrderStatus.NotReady].includes(order.status);
}

export function isFilledOffer({ order }: { order: AbfOrder }) {
    return [AbfOrderStatus.Fulfilled, AbfOrderStatus.InDefault, AbfOrderStatus.Closed].includes(order.status);
}

export function getLoanAddressFromOrder(order: AbfOrder | undefined) {
    if (!order) return undefined;
    const [address] = PublicKey.findProgramAddressSync(
        [utfEncodeString("loan_vault"), new PublicKey(order.address).toBuffer()],
        ABF_PID
    );
    return address.toString();
}
