import { useMemo, useState } from "react";

import { LOADING_ERROR, Result } from "@bridgesplit/utils";
import { BsMetaUtil, StrategyExpanded, useDeleteStrategyTransaction } from "@bridgesplit/abf-react";
import { useAppDialog, AppDialog } from "app/utils";
import { COPY } from "app/constants";
import { TrackEventWithProps } from "app/types";
import { useTrack } from "app/hooks";
import { DialogWrapper, CheckboxRow, Text } from "@bridgesplit/ui";

import { AppButton, DialogHeader } from "../../common";
import { allTransactionsSucceeded, useTransactionSender } from "../../transactions";

export default function StrategyDeleteDialog() {
    const { getData } = useAppDialog();
    const strategy = getData(AppDialog.StrategyDelete)?.strategy;

    const [acceptedWarning, setAcceptedWarning] = useState<boolean>(false);

    const submit = useDeleteStrategy();

    const errorMessage = useMemo(() => {
        if (strategy?.totalBalance)
            return `You must first withdraw your offer balance of ${BsMetaUtil.formatAmount(
                strategy.principalMetadata,
                strategy.totalBalance
            )}`;
        if (strategy?.loanStats?.activeLoanCount)
            return `You cannot delete your offer while you have ${strategy?.loanStats?.activeLoanCount} active loans`;

        return undefined;
    }, [strategy]);

    return (
        <DialogWrapper>
            <DialogHeader
                header={AppDialog.StrategyDelete}
                description={`Are you sure you want to delete this ${COPY.STRATEGY_TERM}?`}
            />

            {errorMessage ? (
                <Text color="error" variant="body2">
                    {errorMessage}
                </Text>
            ) : (
                <CheckboxRow
                    label="I understand that all records of my deposits, loans, and offers will be permanently lost"
                    value={acceptedWarning}
                    setValue={() => setAcceptedWarning((prev) => !prev)}
                />
            )}

            <AppButton
                isTransaction={false}
                disabled={!acceptedWarning}
                color="error"
                asyncCta={{
                    onClickWithResult: () => submit(strategy),
                    options: { alertOnError: true }
                }}
                variant="contained"
            >
                {AppDialog.StrategyDelete}
            </AppButton>
        </DialogWrapper>
    );
}

function useDeleteStrategy() {
    const { close } = useAppDialog();
    const { trackWithProps } = useTrack();

    const deleteStrategyTransaction = useDeleteStrategyTransaction();
    const sendTransactions = useTransactionSender();

    return async function submit(strategy: StrategyExpanded | undefined) {
        if (!strategy) return Result.errFromMessage(LOADING_ERROR);

        const res = await sendTransactions(deleteStrategyTransaction, {
            strategyIdentifier: strategy.strategy.identifier
        });

        if (!allTransactionsSucceeded(res)) return res;

        trackWithProps(TrackEventWithProps.SubmitDeleteStrategy, {
            strategyId: strategy.strategy.identifier
        });

        close();
        return Result.ok();
    };
}
