import {
    LoanStatus,
    RoleView,
    useActiveEscrow,
    useLoanInfos,
    MarketType,
    useAccessLevel
} from "@bridgesplit/abf-react";
import { useLoansPagination } from "app/api";
import { AbfOrderFundingType } from "@bridgesplit/abf-sdk";

import { LoanCards } from "../../loans";
import { useMarketContext } from "../common";

export default function BorrowExistingLoans() {
    const { activeEscrow } = useActiveEscrow();
    const { principalMint } = useMarketContext();
    const pagination = useLoansPagination({ initialPageSize: 5, initialState: { principalMint } });
    const { isBeta } = useAccessLevel();

    const { data: loans, totalLoans } = useLoanInfos({
        loanFilter: {
            borrowerEscrow: activeEscrow,
            marketType: MarketType.Markets,
            ignoreRefinanced: true,
            fundingTypes: [AbfOrderFundingType.Standard]
        },
        pagination: pagination.params,
        skip: !activeEscrow || !isBeta
    });

    return (
        <LoanCards
            view={RoleView.Borrow}
            defaultFilter={LoanStatus.ActiveLoan}
            loans={isBeta ? loans : []}
            pagination={{ controller: pagination, totalLoans }}
        />
    );
}
