import { useMemo } from "react";

import {
    DEFAULT_PUBLIC_KEY,
    NullableRecord,
    TIME,
    filterNullableRecord,
    filterTruthy,
    removeDuplicates,
    removeDuplicatesByProperty
} from "@bridgesplit/utils";
import { BsMetadata, getDurationInSeconds } from "@bridgesplit/abf-sdk";
import { useMemoizedKeyMap } from "@bridgesplit/ui";

import { useLendingStrategyInfosQuery, useStrategyTemplatesQuery } from "../reducers";
import { BsMetaUtil, useAbfTypesToUiConverter } from "../utils";
import {
    AbfLendingStrategyAssetTerms,
    AbfLendingStrategyOfferCollateralFilter,
    LendingStrategyFilter,
    PresetStrategyTerms,
    PresetTerms,
    STRATEGY_TEMPLATE_ALL_IDENTIFIER,
    StrategyCollateral,
    StrategyExpanded,
    StrategyInfo,
    StrategyTemplateExpanded
} from "../types";
import { STRATEGY_MINT_IDENTIFIER } from "../constants";
import { useOraclePrices } from "./pricing";
import { useEscrowAccounts } from "./escrow";
import { useActiveGroup } from "./group";
import { useUserBetaAccess } from "./points";

export function useStrategies(filter: LendingStrategyFilter, options?: { skip?: boolean }) {
    const { data: rawData, isLoading: queryLoading, isFetching } = useLendingStrategyInfosQuery(filter, options);

    const strategies = Object.values(rawData ?? {});
    const principalMints = strategies.map((s) => s.strategy.principalMint);
    const collateralMints = strategies.map((s) => getCollateralMintsFromStrategy(s)).flat();
    const mints = collateralMints.concat(principalMints);

    const {
        tokensLoading,
        getMetadata,
        convertStrategyInfo,
        convertEscrowAccount,
        convertExternalYieldInfo,
        convertStrategyLoanStats
    } = useAbfTypesToUiConverter(mints);

    const { escrows } = useEscrowAccounts();
    const { getUsdPrice, isLoading: pricesLoading } = useOraclePrices(principalMints);

    const isLoading = queryLoading || tokensLoading || pricesLoading;

    const data = strategies
        .map((d): NullableRecord<StrategyExpanded> => {
            const strategy = convertStrategyInfo(d);

            const mintToOffers = strategy.offerWithTermsAndFilter.reduce((map, { offerFilters, offerTerms }) => {
                for (const filter of offerFilters) {
                    const mint = getMintFromCollateralFilter(filter);
                    if (!mint) continue; // ignore non simple collateral for now
                    const prev = map.get(mint) ?? [];
                    const terms = offerTerms.map(({ offerAssetTerms: { terms } }) => terms);
                    map.set(mint, prev.concat(...terms));
                }

                return map;
            }, new Map<string, AbfLendingStrategyAssetTerms[]>());

            const collateral = Array.from(mintToOffers.entries())
                .map(([mint, terms]): NullableRecord<StrategyCollateral> => ({ metadata: getMetadata(mint), terms }))
                .filter(filterNullableRecord);
            const principalMetadata = getMetadata(d.strategy.principalMint);
            // set to null to allow non-initialized on-chain strategies
            const escrowAccount = escrows?.find((escrow) => escrow.address === d.strategy.escrow) ?? null;
            const externalYieldInfo = strategy.externalYieldInfo
                ? convertExternalYieldInfo(strategy.externalYieldInfo, principalMetadata?.decimals)
                : null;
            return {
                ...strategy,
                externalYieldInfo,
                totalBalance: strategy.strategyEscrowBalance + (externalYieldInfo?.balance ?? 0),
                collateral,
                principalUsdPrice: getUsdPrice(d.strategy.principalMint),
                principalMetadata,
                escrowAccount: escrowAccount ? convertEscrowAccount(escrowAccount, d.strategy.principalMint) : null,
                // todo get loans from escrow
                loanStats: convertStrategyLoanStats(d.loanStats, d.strategy.principalMint)
            };
        })
        .filter(filterNullableRecord);

    return { data: isLoading ? undefined : data, isLoading, isFetching };
}

export function useStrategyTemplates({
    tokens,
    presets: allPresets,
    principalMint,
    skip
}: {
    presets: PresetTerms[] | undefined;
    tokens: BsMetadata[] | undefined;
    principalMint: string | undefined;
    skip?: boolean;
}) {
    const { data: rawData, ...query } = useStrategyTemplatesQuery(undefined, { skip });
    const mintToToken = useMemoizedKeyMap(tokens, (t) => t.assetMint);
    const offerIdToPreset = useMemoizedKeyMap(allPresets, (t) => t.offerTerms.presetOfferIdentifier);

    const data = useMemo(() => {
        if (!rawData || !allPresets || !tokens) return undefined;

        const data = rawData
            .filter(({ template }) => template.principalMint === principalMint || template.principalMint === null)
            .map(({ template, presetsIdentifiers }): StrategyTemplateExpanded => {
                const presets =
                    template.identifier === STRATEGY_TEMPLATE_ALL_IDENTIFIER // hardcode all preset to match all terms
                        ? allPresets.filter(
                              // don't allow lender templates to include long durations
                              ({ strategyTerms: { duration, durationType } }) =>
                                  getDurationInSeconds(duration, durationType) <= TIME.MONTH
                          )
                        : presetsIdentifiers
                              .map((presetId) => offerIdToPreset?.get(presetId))
                              .filter(filterTruthy)
                              .filter((preset) => preset.offerTerms.collateralMint !== principalMint);

                const collateral = presets
                    .map(({ offerTerms: { collateralMint } }): BsMetadata | undefined =>
                        mintToToken?.get(collateralMint)
                    )
                    .filter(filterTruthy);

                return {
                    presets,
                    collateral: removeDuplicatesByProperty(collateral, "assetMint"),
                    template,
                    presetsIdentifiers: presets.map((p) => p.offerTerms.presetOfferIdentifier)
                };
            });
        return data.filter((c) => !!c.collateral.length).sort((a, b) => b.collateral.length - a.collateral.length);
    }, [allPresets, mintToToken, offerIdToPreset, principalMint, rawData, tokens]);

    return { data, ...query };
}

export function useLenderStrategies(params?: { skip?: boolean }) {
    const { groupIdentifier } = useActiveGroup();
    const { isLender } = useUserBetaAccess();
    const { escrows } = useEscrowAccounts();

    //  Skip the query if the user only has the default escrow
    const hasNonDefaultEscrows = escrows?.some((escrow) => escrow.nonce !== DEFAULT_PUBLIC_KEY);

    const query = useStrategies(
        { groups: groupIdentifier ? [groupIdentifier] : [] },
        { skip: !groupIdentifier || params?.skip || !isLender || !hasNonDefaultEscrows }
    );

    if (!isLender || !hasNonDefaultEscrows) return { data: [], isLoading: false, isFetching: false };
    return query;
}

export function useLenderStrategyByEscrow(escrow: string | undefined) {
    const {
        data: strategies,
        isFetching,
        isLoading
    } = useStrategies({ escrowAccounts: escrow ? [escrow] : [] }, { skip: !escrow });
    const strategy = strategies?.find((s) => s.strategy.escrow === escrow);
    return { strategy, notFound: !!strategies && !isLoading && !isFetching && !strategy, isFetching };
}

export function getStrategyName(strategyExpanded: StrategyExpanded | undefined) {
    if (!strategyExpanded) return "";
    const { strategy, principalMetadata } = strategyExpanded;

    if (strategy.name) return strategy.name;

    const principalSymbol = BsMetaUtil.getName(principalMetadata);

    if (strategyExpanded.collateral.length === 1) {
        return `${principalSymbol}/${BsMetaUtil.getSymbol(strategyExpanded.collateral[0].metadata)}`;
    }

    const collateralTags = removeDuplicates(
        strategyExpanded.collateral
            .map((c) => c.metadata.tags)
            .flat()
            .filter(filterTruthy)
    );

    if (collateralTags.length === 1) {
        return `${principalSymbol}/${collateralTags[0]}`;
    }

    return principalSymbol;
}

function getCollateralMintsFromStrategy({ offerWithTermsAndFilter }: StrategyInfo) {
    return offerWithTermsAndFilter
        .map((t) => t.offerFilters.map((filter) => getMintFromCollateralFilter(filter)))
        .filter(filterTruthy)
        .flat();
}

function getMintFromCollateralFilter(filter: AbfLendingStrategyOfferCollateralFilter) {
    if (filter.comparator !== "=" || filter.traitName !== STRATEGY_MINT_IDENTIFIER) return undefined;
    return filter.traitValue;
}

type SharedKey = keyof AbfLendingStrategyAssetTerms & keyof PresetStrategyTerms;
export function isOfferSameAsPreset(offer: AbfLendingStrategyAssetTerms, preset: PresetStrategyTerms) {
    const keys: SharedKey[] = [
        "duration",
        "durationType",
        "maxOutstandingPayments",
        "repaymentRrule",
        "allowEarlyRepayments"
    ];
    return keys.every((key) => offer[key] === preset[key]);
}
