import { useMemo } from "react";

import {
    AbfLedgerAccount,
    AbfLoanInfo,
    AbfLoanVault,
    AbfOrder,
    AbfOrderFundingType,
    AbfOrderStatus,
    AssetTypeIdentifier,
    DEFAULT_FEE_SCHEDULE,
    EscrowSide,
    FeeType,
    LockboxAsset,
    SyndicatedOrderContributor,
    WhirlpoolPositionExpanded
} from "@bridgesplit/abf-sdk";
import {
    NullableRecord,
    filterNullableRecord,
    isBeforeNow,
    isPastNow,
    bsMath,
    getUnixTs,
    filterAllKeysDefined,
    combineCollections,
    STAKED_SOL_MINT,
    removeDuplicatesByProperty,
    TIME,
    convertAnyDate,
    formatDate,
    removeDuplicates,
    removeDuplicatesWithFunction,
    bpsToUiDecimals,
    roundToDecimals
} from "@bridgesplit/utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useMemoizedKeyMap } from "@bridgesplit/ui";

import {
    useFeesQuery,
    useGetFeeScheduleInfosForLoansQuery,
    useLoanBidAccountQuery,
    useLoanInfosQuery
} from "../reducers";
import {
    BsMetaUtil,
    calculateHealthRatio,
    formatTokens,
    getIsLoanEscrowBased,
    getLoanTotalDebt,
    isLoanZeroCoupon,
    isStakedSol,
    summarizeLedgerAccounts,
    useAbfTypesToUiConverter
} from "../utils";
import {
    LoanFilter,
    AbfLoanExpanded,
    AbfLoanExpandedBase,
    LoanCollateral,
    AbfSyndicateExpanded,
    LockboxEvent,
    LoanStatus,
    LoanFilterApi,
    LoanPaginationInfo
} from "../types";
import { useGetSyndicatesByOrders } from "./syndicated";
import { useActiveGroup, useGroupsByEscrows } from "./group";
import { useOraclePrices } from "./pricing";
import { useWhirlpoolPositions } from "./whirlpool";
import { LOAN_REFINANCE_GRACE_BEFORE_DEFAULT } from "../constants";
import { useSkipUnauthenticated } from "./auth";
import { useEscrowAccounts } from "./escrow";

export type LoanInfosParams = {
    loanFilter: LoanFilter;
    // require explicit null to avoid ts error
    pagination?: LoanPaginationInfo | null;
    skip?: boolean;
    overrideSyndicates?: AbfSyndicateExpanded[] | undefined;
};
export function useLoanInfos({ loanFilter: loanFilterRaw, pagination, ...params }: LoanInfosParams) {
    const loanFilter = useMemo((): LoanFilterApi => {
        return {
            lenders: loanFilterRaw.lenderEscrows,
            loan_addresses: loanFilterRaw.loanAddresses,
            borrowers: loanFilterRaw.borrowerEscrow ? [loanFilterRaw.borrowerEscrow] : undefined,
            asset_mint: loanFilterRaw.lockboxMint,
            ignore_refinanced: loanFilterRaw?.ignoreRefinanced,
            market_type: loanFilterRaw?.marketType,
            active: pagination?.activeOnly,
            principal_mints: pagination?.principalMint ? [pagination.principalMint] : undefined,
            order_funding_types: loanFilterRaw.fundingTypes,
            page_size: pagination?.pageSize,
            page: pagination?.page,
            sale_events: !!loanFilterRaw.lenderEscrows?.length,
            sort_side: pagination?.sortSide,
            sort_type: pagination?.sortType
        };
    }, [loanFilterRaw, pagination]);
    const skipIfUnauthenticated = useSkipUnauthenticated("bearer");
    const skip = params?.skip || skipIfUnauthenticated;
    const { groupIdentifier } = useActiveGroup();
    const {
        data: rawData,
        isLoading: queryLoading,
        isFetching: loanInfosFetching
    } = useLoanInfosQuery(loanFilter, { skip });

    const { data: feeAccounts, isFetching: feeAccountsFetching } = useFeesQuery(loanFilter, { skip });

    const { data: feeScheduleInfos, isFetching: feeScheduleInfosFetching } = useGetFeeScheduleInfosForLoansQuery(
        loanFilter,
        { skip }
    );

    const { escrowPubkeys: userEscrows } = useEscrowAccounts();

    const loans = rawData ? Object.values(rawData.loans) : undefined;

    const escrows: string[] = [];
    loans?.forEach(({ loan }) => {
        escrows?.push(loan.borrower);
        escrows?.push(loan.lender);
    });

    const mints = useMemo(() => {
        if (!rawData) return undefined;
        const lockboxMints = new Set(
            Object.values(rawData?.lockboxAssets)
                .map((assets) => assets.map((a) => a.assetKey))
                .flat()
        );
        const eventMints = new Set(
            Object.values(rawData?.lockboxEvents)
                .map((events) => events.map((e) => e.assetKey))
                .flat()
        );

        const principalMints = new Set(Object.values(rawData?.loans ?? {}).map((l) => l.order.principalMint));
        const allMints = combineCollections([lockboxMints, eventMints, principalMints, [STAKED_SOL_MINT]]);
        return removeDuplicates(allMints);
    }, [rawData]);

    const whirlpoolMints = useMemo(() => {
        if (!rawData) return undefined;
        return Object.values(rawData.lockboxEvents)
            .flat()
            .filter((e) => e.assetTypeDiscriminator === AssetTypeIdentifier.OrcaPosition)
            .map((p) => p.assetKey);
    }, [rawData]);

    // since lockboxes can be re-used, we need to map the collateral mint to the lockbox address
    const lockboxNftToLockbox = useMemo(() => {
        if (!rawData) return undefined;

        const lockboxNftToLockbox = new Map<string, LockboxAsset[]>();
        for (const { order, loan } of Object.values(rawData.loans).flat()) {
            const lockboxNft = order.collateralMint;
            const lockboxAssets = rawData.lockboxAssets[loan.address];
            if (lockboxAssets) {
                lockboxNftToLockbox.set(lockboxNft, lockboxAssets);
            }
        }
        return lockboxNftToLockbox;
    }, [rawData]);

    const { data: whirlpools, isFetching: whirlpoolsFetching } = useWhirlpoolPositions(whirlpoolMints);

    const positionToWhirlpool = useMemoizedKeyMap(whirlpools, (w) => w.position.positionMint);

    const {
        getMetadata,
        convertLedgerAccount,
        convertLoan,
        convertOrder,
        convertOrderSchedule,
        convertLockboxAssets,
        convertEscrow,
        convertFeeAccount,
        convertOrderFeeSchedule,
        convertLockboxEvent,
        convertLoanSaleEvents,
        tokensLoading
    } = useAbfTypesToUiConverter(mints);
    const { getUsdPrice, isLoading: pricesLoading } = useOraclePrices(mints);

    const { cache: escrowGroupCache, isLoading: groupsLoading } = useGroupsByEscrows(escrows);

    const syndicatesOrders = loans
        ?.filter(({ order: { fundingType } }) => fundingType === AbfOrderFundingType.Syndicated)
        .map(({ order: { address } }) => address);
    const { getSyndicateByOrder, isFetching: syndicateLoading } = useGetSyndicatesByOrders(syndicatesOrders, {
        overrideSyndicates: params?.overrideSyndicates
    });

    const isFetching = feeAccountsFetching || loanInfosFetching || feeScheduleInfosFetching || whirlpoolsFetching;
    const isLoading = queryLoading || groupsLoading || syndicateLoading || tokensLoading || pricesLoading || isFetching;

    const data = useMemo(() => {
        if (!groupIdentifier) return [];
        if (isLoading || !loans) return undefined;
        return loans
            .map(
                ({
                    loan,
                    ledgerAccounts: ledgerAccountsRaw,
                    order: orderRaw,
                    orderSchedule: orderScheduleRaw,
                    principalEscrowAccount,
                    saleEvents,
                    index
                }): NullableRecord<AbfLoanExpanded> => {
                    const orderSchedule = convertOrderSchedule(orderScheduleRaw);
                    const order = convertOrder(orderRaw);
                    const principalMetadata = getMetadata(order.principalMint);

                    const lockboxAssetsRaw = lockboxNftToLockbox?.get(order.collateralMint);
                    const lockboxAssets = lockboxAssetsRaw ? convertLockboxAssets(lockboxAssetsRaw) : [];
                    const lockboxAssetsMap = new Map(lockboxAssets.map((l) => [l.assetKey, l.amount]));
                    const ledgerAccounts = Object.values(ledgerAccountsRaw).map((l) =>
                        convertLedgerAccount(l, order.principalMint)
                    );

                    // events contain the original deposits
                    const lockboxEventsRaw = rawData?.lockboxEvents?.[loan.address] ?? [];

                    const originalDeposits = filterUniqueLockboxDepositEvents(
                        lockboxEventsRaw.map((event) => convertLockboxEvent(event)),
                        getIsLoanCompleteFromRawData(loan, orderRaw, ledgerAccounts)
                    );
                    const originalLockboxDepositTime = getOriginalLockboxDepositTime(loan, lockboxEventsRaw);

                    // obtain lockbox address for any past deposits or fallback on current lockbox
                    const lockboxAddress =
                        lockboxEventsRaw[0]?.address ?? lockboxAssets.find((l) => l.lockboxAddress)?.lockboxAddress;

                    let collateral: LoanCollateral[] = originalDeposits
                        ?.map(
                            ({
                                assetKey,
                                amount,
                                assetTypeDiscriminator: assetTypeDiscriminatorRaw
                            }): NullableRecord<LoanCollateral> => {
                                const assetTypeDiscriminator =
                                    assetTypeDiscriminatorRaw || AssetTypeIdentifier.SplToken;
                                const whirlpoolPosition = positionToWhirlpool?.get(assetKey) ?? null;

                                const metadata = (() => {
                                    if (assetTypeDiscriminatorRaw === AssetTypeIdentifier.OrcaPosition)
                                        return whirlpoolPosition?.whirlpoolMetadata;
                                    return getMetadata(assetKey, assetTypeDiscriminator);
                                })();
                                const mint = isStakedSol(assetTypeDiscriminator) ? STAKED_SOL_MINT : assetKey;

                                const usdPrice = (() => {
                                    if (assetTypeDiscriminatorRaw === AssetTypeIdentifier.OrcaPosition)
                                        return whirlpoolPosition?.totalPrice;
                                    return getUsdPrice(mint) ?? null;
                                })();

                                return {
                                    usdPrice,
                                    mint,
                                    key: assetKey,
                                    metadata,
                                    amount,
                                    lockboxAmount: lockboxAssetsMap.get(assetKey) ?? 0,
                                    assetTypeDiscriminator,
                                    whirlpoolPosition
                                };
                            }
                        )
                        .filter(filterNullableRecord);
                    collateral = filterDuplicateCollateral(collateral);

                    const feeScheduleRaw = feeScheduleInfos?.[loan.address];
                    const feeAccountsRaw = feeAccounts?.[loan.address];

                    const common: NullableRecord<Omit<AbfLoanExpandedBase, "type" | "lender" | "lenderEscrow">> = {
                        index,
                        status: LoanStatus.ActiveLoan, // this is overriden later
                        apy: orderSchedule.apy,
                        principalAmount: order.principalAmount,
                        principalUsdPrice: getUsdPrice(order.principalMint) ?? 0,
                        loanRequestName: rawData?.loanRequestNames?.[loan.address] ?? null,
                        principalMetadata,
                        originalLockboxDepositTime,
                        saleEvents: saleEvents
                            .map((event) => convertLoanSaleEvents(event, principalMetadata?.decimals))
                            .map((event) => ({
                                ...event,
                                isUserBuyer: !!userEscrows?.includes(event.buyer),
                                isUserSeller: !!userEscrows?.includes(event.seller)
                            }))
                            .sort((a, b) => a.timestamp - b.timestamp),
                        loan: convertLoan(loan),
                        order,
                        orderSchedule,
                        ledgerAccounts,
                        lockboxAddress,
                        collateral,
                        borrower: escrowGroupCache.get(loan.borrower)?.group,
                        claimablePrincipal: principalEscrowAccount ? convertEscrow(principalEscrowAccount).amount : 0,
                        debt: { total: 0, earlyFee: 0, interestAccrued: 0 }, // initially set to 0s for typing purposes
                        borrowerEscrow: escrowGroupCache.get(loan.borrower)?.escrow_account.address,
                        feeSchedule: feeScheduleRaw ? convertOrderFeeSchedule(feeScheduleRaw) : DEFAULT_FEE_SCHEDULE,
                        feeAccounts: feeAccountsRaw
                            ? feeAccountsRaw.map((account) => convertFeeAccount(account, order.principalMint))
                            : []
                    };

                    if (order.fundingType === AbfOrderFundingType.Syndicated) {
                        const syndicateExpanded = getSyndicateByOrder(order.address);
                        return {
                            ...common,
                            type: "syndicated",
                            syndicateExpanded,
                            lender: syndicateExpanded?.maker,
                            lenderEscrow: syndicateExpanded?.syndicate.address
                        };
                    }
                    const lenderEscrowAccount = escrowGroupCache.get(loan.lender);
                    return {
                        ...common,
                        type: "standard",
                        lenderEscrowAccount: lenderEscrowAccount?.escrow_account,
                        lender: lenderEscrowAccount?.group,
                        lenderEscrow: lenderEscrowAccount?.escrow_account.address
                    };
                }
            )
            .filter(filterAllKeysDefined)
            .map((loanExpanded) => {
                let loanAdjusted = overrideLoanWithSales(userEscrows, loanExpanded);
                const status = getLoanStatusFromLoan(loanAdjusted, groupIdentifier);
                loanAdjusted = { ...loanAdjusted, status };
                const debt = getLoanTotalDebt(loanAdjusted);
                loanAdjusted = { ...loanAdjusted, debt };
                return loanAdjusted;
            });
    }, [
        groupIdentifier,
        isLoading,
        loans,
        convertOrderSchedule,
        convertOrder,
        getMetadata,
        lockboxNftToLockbox,
        convertLockboxAssets,
        rawData?.lockboxEvents,
        rawData?.loanRequestNames,
        feeScheduleInfos,
        feeAccounts,
        getUsdPrice,
        convertLoan,
        escrowGroupCache,
        convertEscrow,
        convertOrderFeeSchedule,
        convertLedgerAccount,
        convertLockboxEvent,
        positionToWhirlpool,
        convertLoanSaleEvents,
        userEscrows,
        convertFeeAccount,
        getSyndicateByOrder
    ]);

    return { data: isLoading ? undefined : data, totalLoans: rawData?.total, isLoading, isFetching };
}

function overrideLoanWithSales(userEscrows: string[] | undefined, loanExpanded: AbfLoanExpanded): AbfLoanExpanded {
    if (!loanExpanded.saleEvents.length || !userEscrows?.length) return loanExpanded;

    const userBuyEvents = loanExpanded.saleEvents.filter((s) => s.isUserBuyer);

    if (!userBuyEvents.length) return loanExpanded;
    const lastUserBuyEvent = userBuyEvents[userBuyEvents.length - 1];

    return { ...loanExpanded, apy: lastUserBuyEvent.buyerApy, principalAmount: lastUserBuyEvent.loanSaleAmount };
}

export function getLoanEndTime(loanInfo: AbfLoanInfo | undefined, useGrace: boolean | undefined) {
    if (!loanInfo) {
        return undefined;
    }
    if (loanInfo?.ledgerAccounts?.length === 0) {
        return loanInfo?.loan.loanStartTime;
    } else {
        const finalLedgerAccount = loanInfo?.ledgerAccounts[loanInfo?.ledgerAccounts.length - 1];
        if (!useGrace) {
            return loanInfo?.loan.loanStartTime + finalLedgerAccount.dueTimeOffset;
        }
        return loanInfo?.loan.loanStartTime + finalLedgerAccount.dueTimeOffset + (finalLedgerAccount.gracePeriod ?? 0);
    }
}

export function getLoanSoldTime(loanExpanded: AbfLoanExpanded | undefined, userEscrows: string[] | undefined) {
    return loanExpanded?.saleEvents.find((e) => userEscrows?.includes(e.seller))?.timestamp;
}

export function isLoanActive(loanExpanded: AbfLoanExpanded | undefined) {
    if (!loanExpanded) return false;
    return [LoanStatus.ActiveLoan, LoanStatus.DefaultRisk, LoanStatus.Delinquent].includes(loanExpanded.status);
}

/// loan is in grace on final ledger
export function isLoanInRefinanceGrace(loanInfo: AbfLoanInfo) {
    if (!loanInfo.order.refinanceEnabled) return false;
    if (isLoanComplete(loanInfo)) return false;
    const endTime = getLoanEndTime(loanInfo, false) ?? 0;

    const now = getUnixTs();
    return now < endTime + LOAN_REFINANCE_GRACE_BEFORE_DEFAULT && now > endTime;
}

export function loanHasUnpaidFees(loanInfo: AbfLoanInfo | undefined) {
    if (!loanInfo) return false;
    return (
        ![AbfOrderStatus.Closed, AbfOrderStatus.Refinanced].includes(loanInfo?.order.status) && // num fees not updated when order is closed  &&
        !!loanInfo?.loan.numFeesOutstanding
    );
}

// to force user to pay fees once all ledgers have been paid and there are still payments
export function loanPayFeesRequired(loanInfo: AbfLoanInfo | undefined) {
    if (!loanInfo || !loanHasUnpaidFees(loanInfo)) return false;
    const paymentsComplete = loanInfo.ledgerAccounts.every((l) => l.isFilled);
    return paymentsComplete;
}
export function isLoanComplete(loanInfo: AbfLoanInfo | undefined) {
    if (!loanInfo) return false;
    if (isLoanDefaulted(loanInfo) || isLoanLiquidated(loanInfo)) return false;

    if (loanHasUnpaidFees(loanInfo)) return false;

    // allows early payment
    const paymentsComplete = loanInfo.ledgerAccounts.every((l) => l.isFilled);
    if (paymentsComplete) return true;

    return loanInfo.loan.creditClaimed || loanInfo.loan.debtClaimed;
}

export function getIsLoanCompleteFromRawData(
    loanVault: AbfLoanVault,
    order: AbfOrder,
    ledgerAccounts: AbfLedgerAccount[]
) {
    if (order.status !== AbfOrderStatus.Fulfilled) return false;

    // allows early payment
    const paymentsComplete = Object.values(ledgerAccounts).every((l) => l.isFilled);
    if (paymentsComplete) return true;

    return loanVault.creditClaimed || loanVault.debtClaimed;
}

export function isLoanDefaulted(loanInfo: AbfLoanInfo | undefined) {
    if (!loanInfo) return false;

    if (loanInfo.order.status === AbfOrderStatus.InDefault) {
        return true;
    }

    // flash loans cannot default from time based
    if (loanInfo.order.fundingType === AbfOrderFundingType.FlashLoan) return false;

    const refinanceGrace = loanInfo.order.refinanceEnabled ? LOAN_REFINANCE_GRACE_BEFORE_DEFAULT : 0;

    const numPastDue = loanInfo?.ledgerAccounts.filter(
        (data) =>
            isPastNow(data.dueTimeOffset + loanInfo.loan.loanStartTime + (data.gracePeriod ?? 0) + refinanceGrace) &&
            !data.isFilled
    ).length;

    if (numPastDue > loanInfo.order.maxOutstandingPayments) return true;

    if (
        !isBeforeNow((getLoanEndTime(loanInfo, true) ?? 0) + refinanceGrace) &&
        (numPastDue > 0 || !!loanHasUnpaidFees(loanInfo))
    ) {
        return true;
    }

    return false;
}

export function isLoanLiquidated(loanInfo: AbfLoanInfo | undefined) {
    if (!loanInfo) return false;

    if (loanInfo.order.status === AbfOrderStatus.Liquidated) {
        return true;
    }

    return false;
}

export function getLoanDefaultedDate(loanInfo: AbfLoanInfo | undefined) {
    if (!loanInfo || !isLoanDefaulted(loanInfo)) return undefined;

    const maxOutstandingPayments = loanInfo.order.maxOutstandingPayments ?? 0;
    let missedPayments = 0;

    for (const ledger of loanInfo.ledgerAccounts) {
        if (!ledger.isFilled) {
            missedPayments += 1;
        }
        if (missedPayments >= maxOutstandingPayments) {
            return ledger.dueTimeOffset + loanInfo.loan.loanStartTime;
        }
    }

    return (
        loanInfo.ledgerAccounts.reduce((prev, curr) => Math.max(prev, curr.dueTimeOffset), 0) +
        loanInfo.loan.loanStartTime
    );
}

export function getLoanCompletedDate(loanInfo: AbfLoanInfo | undefined) {
    if (!loanInfo || !isLoanComplete(loanInfo)) return undefined;

    return Math.max(
        loanInfo.loan.lastInteractedTime,
        loanInfo.order.lastInteractedTime,
        ...loanInfo.ledgerAccounts.filter((l) => l.isFilled).map((l) => l.paidTime)
    );
}

export function getMostRecentPaymentDate(loanInfo: AbfLoanInfo | undefined) {
    const lastLedger = loanInfo?.ledgerAccounts.filter((l) => l.isFilled)?.pop();
    if (!lastLedger || !loanInfo) return undefined;
    return lastLedger.dueTimeOffset + loanInfo.loan.loanStartTime;
}

export function filterSyndicateUnclaimedLedgers(
    contributor: SyndicatedOrderContributor | undefined,
    ledgerAccounts: AbfLedgerAccount[]
) {
    if (!contributor) return [];
    return ledgerAccounts.filter(
        (l) => l.isFilled && (contributor?.lastClaimedLedgerId ? l.ledgerId > contributor.lastClaimedLedgerId : true)
    );
}

export function getLoanLateFees(loan: AbfLoanExpanded | undefined) {
    const latePaymentsFees = loan?.feeAccounts
        .filter((feeAccount) => feeAccount.feeType === FeeType.LateRepayment)
        .reduce((prev, l) => prev + Math.max(bsMath.sub(l.amountDue, l.amountPaid) || 0, 0), 0);

    return latePaymentsFees ?? 0;
}

export function getLoanEarlyFees(loan: AbfLoanExpanded | undefined) {
    const earlyPaymentFees = loan?.feeAccounts
        .filter((feeAccount) => feeAccount.feeType === FeeType.EarlyRepayment)
        .reduce((prev, l) => prev + Math.max(bsMath.sub(l.amountDue, l.amountPaid) || 0, 0), 0);

    return earlyPaymentFees ?? 0;
}

export function getLoanTotalFees(loan: AbfLoanExpanded | undefined) {
    const latePaymentsFees = getLoanLateFees(loan);
    const earlyPaymentFees = getLoanEarlyFees(loan);
    const totalFees = bsMath.add(latePaymentsFees, earlyPaymentFees);
    return totalFees ?? 0;
}

export function getLoanMissedPayments(loan: AbfLoanInfo) {
    return loan.ledgerAccounts.filter(
        (l) => isPastNow(l.dueTimeOffset + loan.loan.loanStartTime + (l.gracePeriod || 0)) && !l.isFilled
    );
}

export function getLoanPaymentsInGrace(loan: AbfLoanInfo) {
    return loan.ledgerAccounts.filter((l) => {
        const dueTime = l.dueTimeOffset + loan.loan.loanStartTime;
        const dueTimeWithGrace = dueTime + (l.gracePeriod || 0);
        const now = getUnixTs();
        return !l.isFilled && dueTimeWithGrace > now && now > dueTime;
    });
}

export function loanHasLedgerInGrace(loan: AbfLoanInfo | undefined) {
    if (!loan || isLoanDefaulted(loan)) return false;

    const gracePayments = getLoanPaymentsInGrace(loan);

    return !!gracePayments.length;
}

export function loanHasMissedPayments(loan: AbfLoanInfo | undefined) {
    if (!loan || isLoanDefaulted(loan)) return false;
    const missedPayments = getLoanMissedPayments(loan);

    if (!loan.order.maxOutstandingPayments || !missedPayments.length) return false;

    return missedPayments.length < loan.order.maxOutstandingPayments;
}

export function loanHasOutstandingFees(loan: AbfLoanInfo | undefined) {
    if (!loan || isLoanDefaulted(loan)) return false;
    return (loan.loan.numFeesOutstanding ?? 0) > 0;
}

export function loanApproachingDefault(loan: AbfLoanInfo | undefined) {
    if (!loan || isLoanDefaulted(loan)) return false;
    const missedPayments = getLoanMissedPayments(loan);
    const paymentsInGrace = getLoanPaymentsInGrace(loan);

    if (loan.order.maxOutstandingPayments > 0) {
        return missedPayments.length + paymentsInGrace.length >= loan.order.maxOutstandingPayments;
    } else {
        return paymentsInGrace.length > loan.order.maxOutstandingPayments; // if max outstanding payments = 0, is approaching default if 1 payment is in grace. Any missed payments = default
    }
}

export function getLoanName(loanExpanded: AbfLoanExpanded | undefined) {
    if (!loanExpanded) return "";

    if (loanExpanded?.loanRequestName) return loanExpanded?.loanRequestName;
    const principalName = BsMetaUtil.getSymbol(loanExpanded?.principalMetadata);
    if (!loanExpanded.collateral.length) return principalName;
    return `${principalName}/${formatLoanCollateral(loanExpanded)}`;
}

export function formatLoanDuration(loanExpanded: AbfLoanExpanded) {
    const [start, end] = [loanExpanded.loan.loanStartTime, getLoanEndTime(loanExpanded, false)];
    const shortDuration =
        (end || 0) - start < TIME.DAY && convertAnyDate(start).getDate() === convertAnyDate(end).getDate();
    return shortDuration
        ? `${formatDate(end, "date")} ${formatDate(start, "time")} - ${formatDate(end, "time")}`
        : `${formatDate(start, "date")} - ${formatDate(end, "date")}`;
}

function formatLoanCollateral(loanExpanded: AbfLoanExpanded | undefined) {
    if (!loanExpanded?.collateral.length) return "";

    const hasNfts = loanExpanded.collateral.find((c) => BsMetaUtil.isNonFungible(c.metadata));

    if (hasNfts) {
        return formatTokens(loanExpanded.collateral);
    }

    return removeDuplicatesByProperty(loanExpanded.collateral, "mint").map(({ metadata }) =>
        BsMetaUtil.getSymbol(metadata)
    );
}

function getOriginalLockboxDepositTime(loan: AbfLoanVault, lockboxEvents: LockboxEvent[]) {
    return lockboxEvents.reduce((prev, e) => Math.min(prev, e.timestamp), loan.loanStartTime);
}

function filterUniqueLockboxDepositEvents(lockboxDeposits: LockboxEvent[], isCompleted: boolean) {
    const depositMap = new Map<string, LockboxEvent>();

    const uniqueEvents = removeDuplicatesWithFunction(lockboxDeposits, (d) => d.txn + d.assetKey + d.side);

    // sort by timestamp to ensure deposits are processed in order
    uniqueEvents.sort((a, b) => a.timestamp - b.timestamp);

    for (const [i, deposit] of uniqueEvents.entries()) {
        const prevEntry = depositMap.get(deposit.assetKey);

        // ignore withdraws after the loan is completed
        if (deposit.side === EscrowSide.Withdraw && i === uniqueEvents.length - 1 && isCompleted) {
            continue;
        }

        // find the latest escrow deposit in the case of a top up
        if (!prevEntry || deposit.amount) {
            const diff = deposit.side === EscrowSide.Deposit ? deposit.amount : -deposit.amount;
            const amount = diff + (prevEntry?.amount ?? 0);

            depositMap.set(deposit.assetKey, { ...deposit, amount });
        }
    }

    return Array.from(depositMap.values());
}

export function calculateLoanHealth(loanExpanded: AbfLoanExpanded | undefined) {
    const loanCollateralValue = getLoanCollateralUsdValue(loanExpanded);

    const totalLedgersDebt = summarizeLedgerAccounts(loanExpanded).totalOutstanding;
    const totalOwedValue = bsMath.tokenAMul(
        totalLedgersDebt,
        loanExpanded?.principalUsdPrice,
        loanExpanded?.principalMetadata.decimals
    );

    const health = calculateHealthRatio(totalOwedValue, loanCollateralValue, loanExpanded?.order.liquidationThreshold);
    const ltv = totalOwedValue / loanCollateralValue;
    const liquidationThreshold = loanExpanded?.order.liquidationThreshold;

    return { health, ltv, loanCollateralValue, totalOwedValue, liquidationThreshold };
}

export function getLoanCollateralUsdValue(loanExpanded: AbfLoanExpanded | undefined) {
    if (!loanExpanded) return 0;

    return loanExpanded.collateral
        .map((collateral) =>
            bsMath.tokenAMul(collateral.amount, collateral.usdPrice ?? 0, collateral.metadata.decimals)
        )
        .reduce((acc, value) => bsMath.add(acc, value) ?? 0, 0);
}

export function canLenderReclaimCollateral(loanExpanded: AbfLoanExpanded | undefined) {
    if (!loanExpanded) return false;

    if (!isLoanLiquidated(loanExpanded) && !isLoanDefaulted(loanExpanded)) return false;

    return getIsLoanEscrowBased(loanExpanded);
}

function getLoanStatusFromLoan(loanExpanded: AbfLoanExpanded, groupIdentifier: string | undefined) {
    if (
        (loanExpanded.loan.refinancedTo || loanExpanded.order.status === AbfOrderStatus.Refinanced) &&
        loanExpanded.borrower.groupIdentifier === groupIdentifier
    )
        return LoanStatus.Refinanced;
    if (loanExpanded.saleEvents.length) {
        for (const event of loanExpanded.saleEvents.sort((a, b) => b.timestamp - a.timestamp)) {
            if (event.isUserBuyer) break; // skip all sell events if user more recently bought loan
            if (event.isUserSeller) return LoanStatus.SoldLoanSeller;
        }
    }
    if (isLoanComplete(loanExpanded)) return LoanStatus.CompletedLoan;
    if (isLoanDefaulted(loanExpanded) || isLoanLiquidated(loanExpanded)) return LoanStatus.DefaultedLoan;
    if (loanApproachingDefault(loanExpanded)) return LoanStatus.DefaultRisk;
    if (
        loanHasMissedPayments(loanExpanded) ||
        loanHasLedgerInGrace(loanExpanded) ||
        // for ZC loans, fee repay happens at end of loan so it isn't delinquent
        (loanHasOutstandingFees(loanExpanded) && !isLoanZeroCoupon(loanExpanded))
    )
        return LoanStatus.Delinquent;

    if (isLoanInRefinanceGrace(loanExpanded)) {
        // flash loans don't have a refinance grace period
        if (loanExpanded.order.fundingType === AbfOrderFundingType.FlashLoan) return LoanStatus.ActiveLoan;
        return LoanStatus.RefinanceGrace;
    }

    return LoanStatus.ActiveLoan;
}

export function getLoanCustodianIdentifiers(loanExpanded: AbfLoanExpanded | undefined) {
    if (!loanExpanded) return [];
    return removeDuplicates(loanExpanded?.collateral.map((c) => c.metadata.assetOriginator));
}

export function useLoanBidAccount(loanExpanded: AbfLoanExpanded | undefined, options?: { skip?: boolean }) {
    const {
        data: bidData,
        isLoading,
        isFetching
    } = useLoanBidAccountQuery(loanExpanded?.loan.address ?? skipToken, {
        skip: !loanExpanded || (!isLoanDefaulted(loanExpanded) && !isLoanLiquidated(loanExpanded)) || options?.skip
    });

    const data = useMemo(() => {
        if (!loanExpanded || !bidData) return undefined;
        const percentLiquidated = bpsToUiDecimals(bidData.bid);
        const percentRetained = 1 - percentLiquidated;

        const collateralLiquidated: LoanCollateral[] = [];
        const collateralRetained: LoanCollateral[] = [];

        for (const collateral of loanExpanded.collateral) {
            const amountLiquidated = roundToDecimals(
                collateral.amount * percentLiquidated,
                collateral.metadata.decimals
            );
            const amountRetained = roundToDecimals(collateral.amount * percentRetained, collateral.metadata.decimals);

            if (amountLiquidated) {
                collateralLiquidated.push({
                    ...collateral,
                    amount: amountLiquidated
                });
            }
            if (amountRetained) {
                collateralRetained.push({
                    ...collateral,
                    amount: amountRetained
                });
            }
        }
        return {
            bidData,
            collateralLiquidated,
            collateralRetained,
            percentLiquidated,
            percentRetained
        };
    }, [bidData, loanExpanded]);

    return { data, isLoading, isFetching };
}

type LoanCollateralWithOrcaPosition = LoanCollateral & { whirlpoolPosition: WhirlpoolPositionExpanded };
export function getLoanOrcaCollateral(loanExpanded: AbfLoanExpanded | undefined) {
    return loanExpanded?.collateral.find((c) => !!c.whirlpoolPosition && !!c.lockboxAmount) as
        | LoanCollateralWithOrcaPosition
        | undefined;
}

function filterDuplicateCollateral(collateral: LoanCollateral[]) {
    // if there is any active orca collateral, return only that
    const activeOrcaCollateral = collateral.find((c) => !!c.whirlpoolPosition && !!c.lockboxAmount);
    if (activeOrcaCollateral) return [activeOrcaCollateral];

    return collateral;
}

export function getLoanStrategyIdentifier(loanExpanded: AbfLoanExpanded | undefined) {
    if (!loanExpanded) return undefined;
    if (loanExpanded.type !== "standard") return undefined;
    return loanExpanded.lenderEscrowAccount.nonce;
}
