import { ABF_PID, SYNDICATED_PID, utf } from "@bridgesplit/utils";
import { PublicKey } from "@solana/web3.js";
import { ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID } from "@solana/spl-token";

export function getEscrowAccountForGroup<T extends string | undefined>(groupIdentifier: T, nonce: string): T {
    return deriveEscrowAccount(groupIdentifier, nonce);
}

export function deriveEscrowAccount<T extends string | undefined>(address: T, nonce: string): T {
    return derivePda(address, "escrow", [new PublicKey(nonce).toBytes()]);
}

export function deriveSyndicatedOrderAddress(nonce: string): string {
    const [order] = PublicKey.findProgramAddressSync(
        [utfEncodeString("syndicated_order"), new PublicKey(nonce).toBytes()],
        SYNDICATED_PID
    );
    return order.toString();
}

export function deriveLoanVaultAddress<T extends string | undefined>(orderAddress: T): T {
    return derivePda(orderAddress, "loan_vault");
}

export function derivePda<T extends string | undefined>(
    address: T,
    seed: string,
    extraSeeds?: Uint8Array[],
    programId?: PublicKey
): T {
    if (address === undefined) return address;

    const [escrow] = PublicKey.findProgramAddressSync(
        [utfEncodeString(seed), new PublicKey(address).toBytes(), ...(extraSeeds || [])],
        programId ? programId : ABF_PID
    );
    return escrow.toString() as T;
}

export function getAta<T extends string | undefined>(user: T, mint: T): T {
    if (!mint || !user) return undefined as T;
    return getAssociatedTokenAddressSync(new PublicKey(mint), new PublicKey(user), true).toString() as T;
}

export function isValidPublicKey(address: string) {
    try {
        if (!address) return false;
        new PublicKey(address);
        return true;
    } catch {
        return false;
    }
}

export function utfEncodeString(seed: string) {
    return Buffer.from(utf.encode(seed));
}

/// copied directly to avoid whirlpool package conflict https://github.com/solana-labs/solana-program-library/blob/d72289c79/token/js/src/state/mint.ts#L190
export function getAssociatedTokenAddressSync(
    mint: PublicKey,
    owner: PublicKey,
    allowOwnerOffCurve = false,
    programId = TOKEN_PROGRAM_ID,
    associatedTokenProgramId = ASSOCIATED_TOKEN_PROGRAM_ID
): PublicKey {
    if (!allowOwnerOffCurve && !PublicKey.isOnCurve(owner.toBuffer())) throw new Error();

    const [address] = PublicKey.findProgramAddressSync(
        [owner.toBuffer(), programId.toBuffer(), mint.toBuffer()],
        associatedTokenProgramId
    );

    return address;
}
