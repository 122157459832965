import { useMemo } from "react";

import {
    AbfLoanExpanded,
    BsMetaUtil,
    getLoanStrategyIdentifier,
    parseOrcaAmounts,
    useWhirlpoolClaimFeeTransaction
} from "@bridgesplit/abf-react";
import { WhirlpoolPositionExpanded } from "@bridgesplit/abf-sdk";
import { Image, ORCA_LOGO, Row, Text, TextColor, TextVariant, TooltipText } from "@bridgesplit/ui";
import { formatAddress, formatUsd, LOADING_ERROR, Result } from "@bridgesplit/utils";
import { CheckCircleOutlined, EnergySavingsLeafOutlined, ErrorOutlineOutlined } from "@mui/icons-material";
import { PositionUtil, PositionStatus } from "@orca-so/whirlpool-sdk";
import BN from "bn.js";
import { sqrtPriceX64ToTickIndex } from "@orca-so/whirlpool-client-sdk";

import { useTransactionSender } from "../transactions/util";
import { AppButton } from "./auth";

export function WhirlpoolPositionInfo({ position }: { position: WhirlpoolPositionExpanded | undefined }) {
    return (
        <Row spacing={1}>
            <Text>{BsMetaUtil.formatAmount(position?.tokenA?.metadata, position?.tokenA?.amount)} </Text>
            <Text color="disabled">/</Text>
            <Text>{BsMetaUtil.formatAmount(position?.tokenB?.metadata, position?.tokenB?.amount)} </Text>
        </Row>
    );
}

export function WhirlpoolPositionSummarized({
    position,
    spaceBetween
}: {
    position: WhirlpoolPositionExpanded | undefined;
    spaceBetween?: boolean;
}) {
    return (
        <Row sx={{ width: "100%" }} spaceBetween={spaceBetween} spacing={1}>
            <Row spacing={1}>
                <Image src={ORCA_LOGO} size="16px" variant="circle" />
                <Text>{formatAddress(position?.position.positionMint)}</Text>
            </Row>
            <Text color="caption">{formatUsd(position?.totalPrice)}</Text>
        </Row>
    );
}

export function PriceRangeStatus({
    tickCurrentIndex,
    tickLower,
    tickUpper,
    variant,
    iconOnly
}: {
    tickCurrentIndex: number | undefined;
    tickLower: number | undefined;
    tickUpper: number | undefined;
    variant?: TextVariant;
    iconOnly?: boolean;
}) {
    const details = useMemo(() => {
        if (tickCurrentIndex === undefined || tickLower === undefined || tickUpper === undefined) return null;

        const status = PositionUtil.getPositionStatus(tickCurrentIndex, tickLower, tickUpper);
        const color: TextColor = status === PositionStatus.InRange ? "success" : "error";
        const icon = status === PositionStatus.InRange ? <CheckCircleOutlined /> : <ErrorOutlineOutlined />;
        const caption = status === PositionStatus.InRange ? "In range" : "Out of range";
        return { color, icon, caption };
    }, [tickCurrentIndex, tickLower, tickUpper]);

    if (!details) return null;

    const { color, icon, caption } = details;

    return (
        <TooltipText
            icon={false}
            helpText={iconOnly ? caption : undefined}
            sx={{ gap: 0.5 }}
            variant={variant}
            color={color}
        >
            {icon}
            {iconOnly ? null : caption}
        </TooltipText>
    );
}

export function parseOrcaTicks(whirlpoolPosition: WhirlpoolPositionExpanded | undefined) {
    if (!whirlpoolPosition) return undefined;
    try {
        const { position, whirlpool } = whirlpoolPosition;

        const convert = (sqrtPrice: string) => {
            const bn = new BN(sqrtPrice);
            return sqrtPriceX64ToTickIndex(bn);
        };

        return {
            currentTick: convert(whirlpool.sqrtPrice),
            tickLower: convert(position.lowerSqrtPrice),
            tickUpper: convert(position.upperSqrtPrice)
        };
    } catch (error) {
        Result.err(error);
        return undefined;
    }
}

export function ClaimWhirlpoolFeesButton({
    whirlpoolPosition,
    loanExpanded
}: {
    whirlpoolPosition: WhirlpoolPositionExpanded;
    loanExpanded: AbfLoanExpanded | undefined;
}) {
    const claimFees = useWhirlpoolClaimFeeTransaction();
    const send = useTransactionSender();

    const { position } = whirlpoolPosition;

    const claimableFeesUsd = parseOrcaAmounts(whirlpoolPosition, position.feeAmounts).reduce(
        (prev, curr) => prev + curr.usdAmount,
        0
    );

    async function submit() {
        if (!loanExpanded) return Result.errFromMessage(LOADING_ERROR);
        const strategyIdentifier = getLoanStrategyIdentifier(loanExpanded);
        if (!strategyIdentifier) return Result.errFromMessage("Can't claim fees on a Prime loan");
        return await send(claimFees, { loan: loanExpanded.loan.address, strategyIdentifier });
    }

    return (
        <Row spacing={1}>
            <Text>{formatUsd(claimableFeesUsd)}</Text>
            <AppButton
                variant="text"
                sx={{ ":hover": { background: "none", opacity: 0.6 } }}
                color="success"
                width="max-content"
                isTransaction
                asyncCta={{
                    onClickWithResult: submit
                }}
            >
                Harvest <EnergySavingsLeafOutlined />
            </AppButton>
        </Row>
    );
}
