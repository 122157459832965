import { TIME } from "@bridgesplit/utils";

export const LOAN_HEALTH_THRESHOLD = {
    warning: 0.3,
    danger: 0.1
};

export const LOOP_HEALTH_DANGER_THRESHOLD = 0.03;

export const LOAN_REFINANCE_GRACE_BEFORE_DEFAULT = TIME.MINUTE * 15;
