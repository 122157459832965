import { useMemo, useState } from "react";

import {
    Column,
    FONT_SIZES,
    Icon,
    Image,
    InvisibleSelect,
    Row,
    SelectOption,
    Span,
    StatColumn,
    StatProps,
    Text,
    Tooltip,
    useAppPalette
} from "@bridgesplit/ui";
import {
    filterTruthy,
    formatPercent,
    formatSeconds,
    formatUsd,
    percentUiToDecimals,
    stringLamportsToUiAmount,
    TIME
} from "@bridgesplit/utils";
import {
    BsMetaUtil,
    calculateDurationInSecondsForCompoundedInterest,
    formatLeverage,
    getContinuousCompoundedInterest,
    getLoopSlippage,
    isLstLoop,
    isValidLeverage,
    LoopExpanded
} from "@bridgesplit/abf-react";
import { useLoopPoints } from "app/components/points/stats";
import { LoopRoutePlatform } from "@bridgesplit/abf-sdk";

import { useLoopContext } from "../LoopContext";
import { useWindContext } from "./WindContext";
import { useLoopPointsDetails } from "../../common";

const TIME_OPTIONS: SelectOption<number>[] = [
    { label: "per week", value: TIME.WEEK },
    { label: "per month", value: TIME.MONTH },
    { label: "per year", value: TIME.YEAR }
];
export default function WindNestedStats() {
    const { quote, form, isQuoteFetching } = useWindContext();
    const { loopExpanded } = useLoopContext();
    const pointsDetails = useLoopPointsDetails({ loopExpanded });
    const { isDarkMode } = useAppPalette();

    const [estimateSeconds, setEstimateSeconds] = useState(TIME_OPTIONS[TIME_OPTIONS.length - 1].value);
    const interest = useMemo(() => {
        if (!quote || !loopExpanded) return undefined;
        const principalUsd = loopExpanded.collateralOracle.getUsdAmount(quote.collateralAmount);

        return getContinuousCompoundedInterest({
            principal: principalUsd,
            apy: quote.netApyPct,
            loanDuration: estimateSeconds,
            subtractPrincipal: true
        });
    }, [estimateSeconds, loopExpanded, quote]);

    const apy = quote?.netApyPct;

    const totalBorrow = (quote?.principalAmount ?? 1) * (loopExpanded?.principalOracle.usdPrice ?? 1);
    const additionalLoopStats = useLoopPoints({
        borrowed: totalBorrow,
        variant: "body1",
        metadata: [loopExpanded?.collateralToken, loopExpanded?.principalToken].filter(filterTruthy)
    });

    const stats: StatProps[] = [
        {
            caption: "APY",
            value: formatPercent(apy),
            textColor: (() => {
                if (!apy) return "disabled";
                if (apy < 0) return "error";
                if (isDarkMode) return "success";
                return "secondary";
            })()
        },
        {
            caption: (
                <Row>
                    <Text sx={{ mr: -0.5 }} color="caption">
                        Estimated yield
                    </Text>
                    <InvisibleSelect
                        color="caption"
                        value={estimateSeconds}
                        setValue={setEstimateSeconds}
                        options={TIME_OPTIONS}
                    />
                </Row>
            ),
            value: formatUsd(interest)
        },
        ...additionalLoopStats
    ];

    if (pointsDetails && quote) {
        const formattedLeverage = formatLeverage(quote?.leverageMultiplier);
        stats.push({
            caption: `${pointsDetails.name} rewards`,
            value: (
                <Row spacing={0.5}>
                    <Image hideSkeleton size={FONT_SIZES.body1 + "px"} variant="square" src={pointsDetails.image} />
                    <Text> {formattedLeverage} </Text>
                </Row>
            )
        });
    }

    if (loopExpanded && isLstLoop(loopExpanded)) {
        stats.push({
            caption: "Break-even in",
            value: <LstBreakEven loopExpanded={loopExpanded} />
        });
    }

    if (!form.collateralAmount || !isValidLeverage(form.multiplier) || quote === null) return null;
    return <StatColumn loading={isQuoteFetching} stats={stats} />;
}

const ORCA_FEE_PERCENT = percentUiToDecimals(0.01);

function LstBreakEven({ loopExpanded }: { loopExpanded: LoopExpanded }) {
    const { externalQuote, quote: windQuote, form } = useWindContext();
    if (!externalQuote || externalQuote.type !== LoopRoutePlatform.Jupiter || !windQuote) return null;

    const { quote } = externalQuote;

    const defaultSlippage = percentUiToDecimals(getLoopSlippage(loopExpanded).defaultSlippageUi);
    const slippagePercent = form.percentSlippageDecimals ?? 0 + defaultSlippage;

    // swap fees in both directions
    const swapFeePercent = 2 * ORCA_FEE_PERCENT;
    const totalFeesPercent = swapFeePercent + slippagePercent;
    const swapAmount = stringLamportsToUiAmount(quote.inAmount, loopExpanded.principalToken.decimals);

    const maxFee = swapAmount * totalFeesPercent;

    const maxDuration = calculateDurationInSecondsForCompoundedInterest({
        principal: windQuote.principalAmount,
        desiredInterest: maxFee,
        apy: windQuote.bestQuote.apy
    });
    const interestPerDay = getContinuousCompoundedInterest({
        principal: windQuote.principalAmount,
        apy: windQuote.bestQuote.apy,
        loanDuration: TIME.DAY,
        subtractPrincipal: true
    });

    return (
        <Row spacing={0.5}>
            <Text>{formatSeconds(maxDuration, { maxUnit: "day" })}</Text>
            <Tooltip
                reverseColors
                placement="bottom"
                title={
                    <Column spacing={2} p={1}>
                        <Text sx={{ display: "inline" }} color="caption" variant="body2">
                            Position requires an AMM swap. Fees are typically offset after{" "}
                            <Span sx={{ color: (theme) => theme.palette.text.primary }}>
                                {formatSeconds(maxDuration, { maxUnit: "day" })}
                            </Span>{" "}
                            of staking yield
                        </Text>

                        <StatColumn
                            variant="body2"
                            captionVariant="body2"
                            stats={[
                                { caption: "Orca LP fees", value: formatPercent(swapFeePercent) },
                                { caption: "Max slippage", value: formatPercent(slippagePercent) },
                                {
                                    caption: "Max setup cost",
                                    value: BsMetaUtil.formatAmount(loopExpanded.principalToken, maxFee)
                                },
                                {
                                    caption: "Est. daily yield",
                                    value: BsMetaUtil.formatAmount(loopExpanded.principalToken, interestPerDay)
                                }
                            ]}
                        />
                    </Column>
                }
            >
                <Text color="disabled">
                    <Icon type="tooltip" />
                </Text>
            </Tooltip>
        </Row>
    );
}
