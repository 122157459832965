import { useState } from "react";

import {
    AbfLoanExpanded,
    formatTokens,
    getLoanDefaultedDate,
    getLoanName,
    useLoanWithdrawTransaction
} from "@bridgesplit/abf-react";
import { Column, DialogWrapper, MAX_DIALOG_HEIGHT, OutlinedOption, Row, Text, VerticalScroll } from "@bridgesplit/ui";
import { formatDate } from "@bridgesplit/utils";
import { AppDialog, useAppDialog } from "app/utils";

import { useWithdrawLoanToken } from "../../loan/util";
import { AppButton, DialogHeader, OverlappingMetadataImages } from "../../common";

export default function StrategySeizedCollateralDialog() {
    const { getData } = useAppDialog();
    const data = getData(AppDialog.StrategySeizedCollateral);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const selectedLoan = data?.loans[selectedIndex];

    return (
        <DialogWrapper>
            <DialogHeader
                header={AppDialog.StrategySeizedCollateral}
                description="Withdraw collateral from defaulted loans to your wallet"
            />
            {data?.loans.length === 1 && selectedLoan ? (
                <LoanDisplay l={selectedLoan} />
            ) : (
                <VerticalScroll spacing={1} maxHeight={MAX_DIALOG_HEIGHT}>
                    {data?.loans.map((l, i) => {
                        const isSelected = i === selectedIndex;
                        return (
                            <OutlinedOption
                                sx={{ m: "1.5px" }}
                                key={i}
                                onClick={() => setSelectedIndex(i)}
                                isSelected={isSelected}
                            >
                                <LoanDisplay l={l} />
                            </OutlinedOption>
                        );
                    })}
                </VerticalScroll>
            )}

            {!!selectedLoan && <Cta loanExpanded={selectedLoan} />}
        </DialogWrapper>
    );
}

function LoanDisplay({ l }: { l: AbfLoanExpanded }) {
    return (
        <Row spaceBetween>
            <Column>
                <Text>{getLoanName(l)}</Text>
                <Text variant="body2" color="caption">
                    Defaulted {formatDate(getLoanDefaultedDate(l) ?? l.loan.lastInteractedTime, "relative")}
                </Text>
            </Column>

            <Row spacing={1}>
                <OverlappingMetadataImages disableTooltip size="20px" metadata={l.collateral.map((c) => c.metadata)} />
                <Text>{formatTokens(l.collateral)}</Text>
            </Row>
        </Row>
    );
}

function Cta({ loanExpanded }: { loanExpanded: AbfLoanExpanded }) {
    const loanWithdraw = useLoanWithdrawTransaction();

    const withdraw = useWithdrawLoanToken({ loanExpanded, withdrawTransaction: loanWithdraw, side: "credit" });

    return (
        <AppButton
            isTransaction
            asyncCta={{
                onClickWithResult: withdraw
            }}
        >
            Withdraw
        </AppButton>
    );
}
