import {
    AbfEscrowAccount,
    AbfEscrowedAsset,
    AbfFeeAccount,
    AbfLedgerAccount,
    AbfLoanInfo,
    AbfLoanVault,
    AbfOrder,
    AbfOrderFundingType,
    AbfOrderSchedule,
    AssetTypeIdentifier,
    BsMetadata,
    FeeScheduleInfo,
    LockboxAsset
} from "@bridgesplit/abf-sdk";
import { SortDirection } from "@bridgesplit/utils";

import { AbfUserGroup } from "./group";
import { AbfSyndicateExpanded } from "./syndicate";
import { ExpandedOrderType } from "./order";
import { LockboxEvent } from "./lockbox";
import { TokenBalanceExpanded } from "./escrow";

/** Loans */

export interface AbfLoanResponse {
    loans: Record<string, LoanEntry>;
    lockboxAssets: Record<string, LockboxAsset[]>; // loan address -> LB current assets
    loanRequestNames: Record<string, string>; // loan address -> loan req name
    lockboxEvents: Record<string, LockboxEvent[]>; // loan address -> past deposits
    total: number;
}

export interface LoanEntry {
    index: number;
    order: AbfOrder;
    orderSchedule: AbfOrderSchedule;
    loan: AbfLoanVault;
    ledgerAccounts: Record<string, AbfLedgerAccount>;
    principalEscrowAccount: AbfEscrowedAsset | null;
    saleEvents: LoanSaleEvent[];
}

export interface AbfLoanExpandedBase extends AbfLoanInfo {
    index: number; // overall index of loan
    status: LoanStatus;
    type: ExpandedOrderType;
    borrower: AbfUserGroup;
    borrowerEscrow: string;
    lender: AbfUserGroup;
    lenderEscrow: string;
    lockboxAddress: string;
    loanRequestName: string | null;
    collateral: LoanCollateral[];
    claimablePrincipal: number;
    principalMetadata: BsMetadata;
    feeSchedule: FeeScheduleInfo;
    feeAccounts: AbfFeeAccount[];
    principalUsdPrice: number;
    saleEvents: LoanSaleEventExpanded[];
    debt: LoanDebt;
    // specific to the active user's view
    apy: number;
    principalAmount: number;
    originalLockboxDepositTime: number;
}

export interface LoanDebt {
    total: number;
    earlyFee: number;
    interestAccrued: number;
}

interface AbfLoanExpandedStandard extends AbfLoanExpandedBase {
    type: "standard";
    lenderEscrowAccount: AbfEscrowAccount;
}

interface AbfLoanExpandedSyndicated extends AbfLoanExpandedBase {
    type: "syndicated";
    syndicateExpanded: AbfSyndicateExpanded;
}

export type AbfLoanExpanded = AbfLoanExpandedStandard | AbfLoanExpandedSyndicated;

export interface LoanCollateral extends TokenBalanceExpanded {
    mint: string;
    amount: number;
    lockboxAmount: number;
    usdPrice: number | null;
    metadata: BsMetadata;
    assetTypeDiscriminator: AssetTypeIdentifier;
}

export enum LoanInfoSorting {
    NextPayment,
    EndTime,
    StartTime,
    Principal,
    LoanHealth
}

export enum MarketType {
    Prime = "Prime",
    Markets = "Markets"
}

export interface LoanFilterApi {
    lenders?: string[];
    loan_addresses?: string[];
    borrowers?: string[];
    asset_mint?: string;
    principal_mints?: string[];
    active?: boolean;
    // required to prevent edge cases
    order_funding_types?: AbfOrderFundingType[];
    page_size?: number;
    page?: number;
    sale_events?: boolean;
    sort_side?: SortDirection; // 0 = asc, 1 = desc
    sort_type?: LoanInfoSorting;
    market_type?: MarketType;
    ignore_refinanced?: boolean;
}

export interface LoanFilter {
    lenderEscrows?: string[];
    borrowerEscrow?: string;
    loanAddresses?: string[];
    lockboxMint?: string;
    fundingTypes?: AbfOrderFundingType[];
    marketType?: MarketType;
    ignoreRefinanced?: boolean;
}

export interface LoanPaginationInfo {
    activeOnly: boolean;
    page: number;
    pageSize: number;
    sortSide: SortDirection;
    sortType: LoanInfoSorting;
    principalMint?: string;
}

export interface LoanSalesFilter {
    buyers?: string[];
    sellers?: string[];
}

export interface LoanSaleEvent {
    id: number;
    loanAddress: string;
    buyer: string;
    seller: string;
    loanSaleAmount: number;
    timestamp: number;
    sellerApy: number;
    buyerApy: number;
    principalPaid: number;
    interestPaid: number;
    feePaid: number;
}

export interface LoanSaleEventExpanded extends LoanSaleEvent {
    isUserBuyer: boolean;
    isUserSeller: boolean;
}

export enum LoanStatus {
    CompletedLoan = "Repaid",
    ActiveLoan = "Ongoing",
    DefaultedLoan = "Defaulted",
    DefaultRisk = "Default Risk",
    Delinquent = "Delinquent",
    LedgerCreated = "Pending Loan",
    Refinanced = "Refinanced",
    RefinanceGrace = "Pending Refinance",
    SoldLoanSeller = "Withdrawn"
}
