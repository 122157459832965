import { isLstLoop } from "@bridgesplit/abf-react";
import { LoopRoutePlatform } from "@bridgesplit/abf-sdk";
import { Text } from "@bridgesplit/ui";

import { useLoopContext } from "../LoopContext";

const messages = {
    [LoopRoutePlatform.Meteora]: "Setting up your position may include a small fee dependent on pool ratio.",
    [LoopRoutePlatform.Jupiter]: "Setting up your position includes a small Jupiter swap fee."
} as const;

export default function WindRiskMessage() {
    const { loopExpanded } = useLoopContext();

    if (!loopExpanded) {
        return null;
    }

    if (isLstLoop(loopExpanded)) {
        // message is handled in WindNestedStats
        return null;
    }

    const message = loopExpanded?.type && messages[loopExpanded.type];
    if (message) {
        return (
            <Text variant="body2" color="caption">
                {message} Closing positions early may result in a loss
            </Text>
        );
    }
    return null;
}
