import { ReactNode, createContext, useContext, useState } from "react";

import { Dialog, Drawer, drawerClasses, useMediaQuery } from "@mui/material";
import { DispatchType } from "@bridgesplit/react";

import { Column } from "../components";
import { MediaQuery, SxType } from "../types";
import { DIALOG_WINDOW_CENTER_MIN_HEIGHT, SPACING, useAppPalette } from "../theme";
import { MEDIA } from "../util";

/**
 * -- Overview --
 * This context allows for exactly 1 dialog (modal) to be `open` in the app
 * Each dialog must have a unique identifier and wrapped in a DialogWrapper
 * Dialogs should be globally managed in a single component to ensure that duplicate dialogs aren't open
 *
 * -- Data --
 * If a dialog needs specific data (props), it should receive it through the `data` property in context
 * The `data` property is initialized to undefined and force typed to maximize flexibility
 * To pass in data, pass in the data typing into useDialog and pass in the optional newData param
 *
 * -- Registering a Dialog --
 * Add dialog identifier to enum of identifiers
 * Create a dialog component that is wrapped in DialogWrapper
 * Register dialog component in the app's global dialog component
 * Open the dialog using useDialog (passing in data if necessary)
 */
interface DialogState {
    openIdentifiers: string[];
    setOpenIdentifiers: DispatchType<string[]>;
    setData: DispatchType<Record<string, unknown>>;
    preventClose: boolean;
    setPreventClose: DispatchType<boolean>;
    data: Record<string, unknown>;
}
const ModalContext = createContext<DialogState | null>(null);

export function DialogProvider({ children }: { children: ReactNode }) {
    const [openIdentifiers, setOpenIdentifiers] = useState<string[]>([]);
    const [data, setData] = useState<Record<string, unknown>>({});
    const [preventClose, setPreventClose] = useState<boolean>(false);

    return (
        <ModalContext.Provider
            value={{
                openIdentifiers,
                setOpenIdentifiers,
                data,
                setData,
                preventClose,
                setPreventClose
            }}
        >
            {children}
        </ModalContext.Provider>
    );
}

const DIALOG_ERROR = new Error("Loopscale UI Error: Dialog must be wrapped in a DialogProvider");
export function useDialog() {
    const context = useContext(ModalContext);
    if (!context) throw DIALOG_ERROR;
    const { setOpenIdentifiers, openIdentifiers, data: rawData, setData, preventClose, setPreventClose } = context;

    function getData<DataType>(identifier: string | undefined): DataType | undefined {
        if (!identifier || !(identifier in rawData)) return undefined;

        return rawData?.[identifier] as DataType;
    }

    function open<DataType>(identifier: string, newData?: DataType) {
        setData({ [identifier]: newData });
        setOpenIdentifiers([identifier]);
    }

    function addToStack<DataType>(identifier: string, newData?: DataType) {
        setData((prev) => ({ ...prev, [identifier]: newData }));
        setOpenIdentifiers((prev) => [...prev, identifier]);
    }

    function close() {
        const lastIdentifier = openIdentifiers[openIdentifiers.length - 1];
        // close the last open dialog
        setOpenIdentifiers((prev) => prev.filter((id) => id !== lastIdentifier));
        setPreventClose?.(false);
    }

    function _setPreventClose(preventClose: boolean) {
        setPreventClose?.(preventClose);
    }

    return {
        open,
        close,
        isOpen: !!openIdentifiers.length,
        openIdentifiers,
        getData,
        preventClose,
        setPreventClose: _setPreventClose,
        addToStack
    };
}

type DialogWrapperPropsInternal = {
    children: ReactNode;
    sx?: SxType;
    spacing?: number;
    preventClose?: boolean;
    onClose?: () => void;
    maxWidth?: number;
    padding?: number;
    forceOpen?: boolean;
    drawerQuery?: MediaQuery;
};

export type DialogWrapperProps = DialogWrapperPropsInternal & { isDrawer?: boolean };

export function DialogWrapper({ isDrawer, ...props }: DialogWrapperProps) {
    if (isDrawer) return <DialogDrawer {...props} />;
    return <DialogModalWrapper {...props} />;
}

function DialogModalWrapper({
    children,
    sx,
    spacing = 2,
    preventClose,
    onClose,
    maxWidth = 400,
    padding = 2,
    forceOpen
}: DialogWrapperProps) {
    const { paperBackground } = useAppPalette();
    const { close, isOpen, preventClose: preventCloseState } = useDialog();

    return (
        <Dialog
            sx={{
                // use top align to prevent vertical jumping
                "& .MuiDialog-container": {
                    alignItems: "flex-start",
                    height: "100%"
                },
                "& .MuiDialog-paper": {
                    background: paperBackground,
                    maxWidth: `${maxWidth}px`,
                    width: `calc(100vw - ${SPACING * 4}px)`,
                    my: 4,
                    [`@media (min-height: ${DIALOG_WINDOW_CENTER_MIN_HEIGHT}px)`]: {
                        my: SPACING * 16 + "px",
                        maxHeight: `calc(100vh - ${SPACING * 16 * 2}px)`
                    }
                }
            }}
            onClose={() => {
                if (preventClose || preventCloseState) return;
                close();
                onClose?.();
            }}
            open={forceOpen ?? !!isOpen}
        >
            <Column spacing={spacing} sx={{ p: padding, ...sx }}>
                {children}
            </Column>
        </Dialog>
    );
}

function DialogDrawer({
    children,
    sx,
    spacing = 2,
    preventClose,
    onClose,
    maxWidth = 400,
    padding = 2,
    forceOpen,
    drawerQuery = MEDIA.LG
}: DialogWrapperProps) {
    const { paperBackground } = useAppPalette();
    const { close, isOpen, preventClose: preventCloseState } = useDialog();
    const isMobile = useMediaQuery(drawerQuery.below);

    return (
        <Drawer
            anchor={isMobile ? "top" : "right"}
            sx={{
                [`.${drawerClasses.paper}`]: {
                    background: paperBackground,
                    [drawerQuery.above]: { width: "70vw", maxWidth: `${maxWidth}px` },
                    [drawerQuery.below]: { maxHeight: "90vh" }
                }
            }}
            onClose={() => {
                if (preventClose || preventCloseState) return;
                close();
                onClose?.();
            }}
            open={forceOpen ?? !!isOpen}
        >
            <Column spacing={spacing} sx={{ p: padding, flexGrow: 1, ...sx }}>
                {children}
            </Column>
        </Drawer>
    );
}
