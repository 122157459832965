import { useState } from "react";

import {
    Column,
    DIALOG_PADDING,
    DialogWrapper,
    MAX_DIALOG_HEIGHT,
    Row,
    SearchInput,
    Text,
    VerticalScroll,
    useAppPalette
} from "@bridgesplit/ui";
import { BsMetaUtil } from "@bridgesplit/abf-react";
import { textContains } from "@bridgesplit/utils";
import { AppDialog } from "app/utils";

import { TokenImage } from "../../common";
import { useMarketDialog } from "../util";
import MarketDialogHeader from "./common";

export function SelectTokenDialogInternal() {
    const { getData, close } = useMarketDialog();

    const data = getData(AppDialog.SelectToken);
    const { hoverBackground } = useAppPalette();
    const [search, setSearch] = useState<string>("");

    const tokens = data?.tokens?.filter((token) =>
        search
            ? token.assetMint === search ||
              textContains([BsMetaUtil.getName(token), BsMetaUtil.getSymbol(token)], search)
            : true
    );

    const header = data?.header ?? "Select token";

    return (
        <Column spacing={2} sx={{ mx: -DIALOG_PADDING }}>
            <MarketDialogHeader padding header={header} />

            <SearchInput
                sx={{ px: DIALOG_PADDING }}
                placeholder="Search by symbol or address"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
            />

            <VerticalScroll maxHeight={MAX_DIALOG_HEIGHT} spacing={0}>
                {tokens?.map((t) => (
                    <Row
                        key={t.assetMint}
                        sx={{
                            px: DIALOG_PADDING,
                            py: 0.5,
                            cursor: "pointer",
                            ":hover": { background: hoverBackground }
                        }}
                        onClick={() => {
                            data?.setToken?.(t);
                            close();
                        }}
                        spaceBetween
                    >
                        <Row spacing={1.5}>
                            <TokenImage metadata={t} size="md" />

                            <Text>{BsMetaUtil.getSymbol(t)}</Text>
                        </Row>
                    </Row>
                ))}
            </VerticalScroll>
        </Column>
    );
}

export default function SelectTokenDialog() {
    return (
        <DialogWrapper>
            <SelectTokenDialogInternal />
        </DialogWrapper>
    );
}
