import { Icon, Row, StatColumn, Text, TextColor, TextVariant, Tooltip, useAppPalette } from "@bridgesplit/ui";
import { Divider } from "@mui/material";
import { formatPercent, lessThan, TIME } from "@bridgesplit/utils";
import { BsMetadata } from "@bridgesplit/abf-sdk";
import {
    calculateLtvFromLeverage,
    LoopscalePointSource,
    PointsInfo,
    PointSource,
    useCollateralMultipliers,
    useCollateralPointsSourcesUtil,
    useRewardsCalculation
} from "@bridgesplit/abf-react";

import {
    generateLeverageStat,
    PointSourceMap,
    RenderAdditionalStat,
    TooltipContent,
    useCreateCollateralStats,
    useCreateMultiplierLoops,
    useCreateTotalMultiplierStat
} from "./common";
// Types

export interface RateDisplayProps {
    // pass in all apys to calculate the max char width
    allApys?: number[];
    showApyLabel?: boolean;
    variant?: TextVariant;
    tooltip?: "underline" | "icon";
}

interface RateAndRewardsProps {
    apy: number | undefined;
    pointSources: PointSource[];
    loopscalePointSources?: LoopscalePointSource[];
    metadata?: BsMetadata[];
    loopLeverage?: number;
    rateDisplayProps?: RateDisplayProps;
    totalAmount?: number;
    isSpecific?: boolean;
}

interface TooltipBreakdownProps {
    multipliers: PointsInfo[];
    collateralMultipliers?: PointsInfo[];
    totalMultipliers: PointsInfo[];
    additionalMultipliers?: PointsInfo[];
    hasMultiplier: boolean;
    totalAmount: number;
    isSpecific: boolean;
}

export function RateAndRewardsBreakdown({
    apy,
    pointSources,
    loopscalePointSources,
    metadata,
    loopLeverage,
    rateDisplayProps,
    totalAmount = 1000,
    isSpecific = false
}: RateAndRewardsProps): JSX.Element | null {
    const { prime, secondary } = useAppPalette();
    const { hasLoopPointMultiplierAboveBase, getLoopscaleMultipliers, isLoading } = useCollateralPointsSourcesUtil();

    // Hook to summarize point sources.
    const { finalRewards } = useRewardsCalculation({
        pointSources,
        loopscalePointSources,
        metadata,
        loopLeverage
    });

    const baseStats = getLoopscaleMultipliers(loopscalePointSources);
    const leverageStat = generateLeverageStat(loopLeverage);
    const multipliers = useCollateralMultipliers(metadata, loopscalePointSources, true);
    const loopscaleRewards = [...(baseStats ? baseStats : []), ...(leverageStat ? [leverageStat] : [])];

    const additionalRewards = finalRewards.filter((p) => p.program !== PointSource.Loopscale);
    const loopscaleRewardTotal = finalRewards.filter((p) => p.program === PointSource.Loopscale);

    // If RateAndRewardBreakdown has loopLeverage it means its being used for loops. We need to multiply by ltv so that we can get an accurate points calculation since principal borrowed is ltv*totalAmount.
    const ltv = loopLeverage !== undefined ? calculateLtvFromLeverage(loopLeverage) : 1;
    const totalAmountWithLtvCheck = totalAmount * ltv;

    const hasMultiplier =
        hasLoopPointMultiplierAboveBase(metadata, loopscalePointSources ?? []) || multipliers.length > 0;
    const background = hasMultiplier ? prime : secondary;
    return (
        <Tooltip
            reverseColors
            title={
                <TooltipBreakdown
                    multipliers={loopscaleRewards}
                    collateralMultipliers={multipliers}
                    totalMultipliers={loopscaleRewardTotal}
                    hasMultiplier={hasMultiplier}
                    additionalMultipliers={additionalRewards}
                    totalAmount={totalAmountWithLtvCheck}
                    isSpecific={isSpecific}
                />
            }
        >
            <Row spacing={0.5}>
                <PointSourceMap pointSources={pointSources} isLoading={isLoading} background={background} />
                <RateDisplay {...rateDisplayProps} apy={apy} />
            </Row>
        </Tooltip>
    );
}

function RateDisplay({
    apy,
    allApys,
    showApyLabel,
    variant,
    tooltip = "underline"
}: RateDisplayProps & { apy: number | undefined }): JSX.Element {
    const color: TextColor = lessThan(Number(apy), 0) ? "caption" : "body";
    const label = showApyLabel ? " APY" : "";
    const icon = tooltip === "icon" ? <Icon type="tooltip" /> : undefined;

    const apyExists = apy !== undefined;

    if (!allApys?.length) {
        return (
            <Text svgColor="disabled" variant={variant} underline={tooltip === "underline"} color={color}>
                {apyExists && formatPercent(apy)}
                {label}
                {icon}
            </Text>
        );
    }

    const customDigits = 3;
    const maxCharWidth = allApys.reduce((acc, apy) => Math.max(acc, formatPercent(apy, { customDigits }).length), 0);

    return (
        <Row sx={{ alignItems: "flex-end" }}>
            <Text
                svgColor="disabled"
                variant={variant}
                underline={tooltip === "underline"}
                color={color}
                sx={{ display: "inline", width: `${maxCharWidth}ch` }}
            >
                {apyExists && formatPercent(apy, { customDigits })}
                {label}
                {icon}
            </Text>
        </Row>
    );
}

function TooltipBreakdown({
    multipliers,
    collateralMultipliers,
    additionalMultipliers,
    totalMultipliers,
    hasMultiplier,
    totalAmount,
    isSpecific
}: TooltipBreakdownProps): JSX.Element {
    const { prime, secondary } = useAppPalette();
    const { getTruePointsPerDollarPerHour } = useCollateralPointsSourcesUtil();

    const stats = useCreateMultiplierLoops(multipliers);
    const collateralStats = useCreateCollateralStats(collateralMultipliers, hasMultiplier);

    // Idlecap since all multipliers are relative to idle cap
    const baseRate = (getTruePointsPerDollarPerHour(LoopscalePointSource.IdleCap) ?? 0) * TIME.HOUR;

    const totalMultiplier = useCreateTotalMultiplierStat({
        sumStat: totalMultipliers,
        baseRate,
        showMultiplier: false,
        totalAmountUsd: totalAmount,
        isSpecific
    });

    const background = hasMultiplier ? prime : secondary;

    return (
        <TooltipContent>
            <Row spacing={1}>
                <Text sx={{ color: background }}>
                    <Icon type="points" />
                </Text>
                <Text variant="body1">Loopscale rewards</Text>
            </Row>

            <StatColumn stats={[...stats, ...(collateralStats ?? []), ...totalMultiplier]} />
            {additionalMultipliers && additionalMultipliers.length > 0 && (
                <>
                    <Divider />
                    {additionalMultipliers.map((multiplier, index) => (
                        <RenderAdditionalStat key={index} multiplier={multiplier} />
                    ))}
                </>
            )}
        </TooltipContent>
    );
}
