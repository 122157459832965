import { formatNum, formatPercent, formatUsd } from "@bridgesplit/utils";
import { Row, Table, TableColumn, Text, createRows, equalWidthColumns } from "@bridgesplit/ui";
import { BsMetaUtil, isWhirlpoolMetadata, RoleView, useCollateralPointsSourcesUtil } from "@bridgesplit/abf-react";
import { COPY } from "app/constants";

import { CollateralMarket } from "./types";
import { TokenImage, getMarketPath } from "../../common";
import { CollateralLabel } from "../../points";

export default function CollateralMarketsTable({ markets }: { markets: CollateralMarket[] | undefined }) {
    return (
        <Table
            onRowNavigatePath={({ data: { metadata, principalMetadata } }) =>
                getMarketPath({
                    view: RoleView.Borrow,
                    token: principalMetadata,
                    collateralMint: metadata.assetMint
                })
            }
            paginationProps={{
                pageSize: 5,
                type: "managed",
                defaultSortKey: "balance"
            }}
            emptyPlaceholder="No collateral found"
            loading={!markets}
            rows={createRows(markets, (s) => s.metadata.assetMint)}
            columns={equalWidthColumns(columns)}
        />
    );
}

const columns: TableColumn<CollateralMarket>[] = [
    {
        dataIndex: "asset",
        title: "Asset",
        render: ({ data }) => (
            <Row spacing={1}>
                <TokenImage metadata={data.metadata} size="md" />
                <CollateralApyLabel market={data} />
            </Row>
        ),
        sortBy: ({ data: { metadata } }) => BsMetaUtil.getSymbol(metadata)
    },
    {
        dataIndex: "balance",
        title: "Your balance",
        align: "right",
        render: ({ data: { metadata, userBalance } }) => {
            if (!userBalance) {
                return <Text color="disabled"> 0 </Text>;
            }
            if (BsMetaUtil.isNonFungible(metadata) && isWhirlpoolMetadata(metadata)) {
                return (
                    <>
                        {formatNum(userBalance)} {BsMetaUtil.getSymbol(metadata)}
                    </>
                );
            }
            return <> {BsMetaUtil.formatAmount(metadata, userBalance)} </>;
        },
        sortBy: ({ data: { usdValue } }) => usdValue
    },
    {
        dataIndex: "maxBorrow",
        title: "Borrow limit",
        toolTip: "The max dollar value you can borrow based on your balance and this token's LTV",
        align: "right",
        render: ({ data: { usdValue, refinanceInfo } }) => {
            if (!usdValue) return <Text color="disabled"> 0 </Text>;
            return <> {formatUsd(usdValue * refinanceInfo.ltv)} </>;
        },
        sortBy: ({ data: { usdValue, refinanceInfo } }) => usdValue * refinanceInfo.ltv
    },
    {
        dataIndex: "ltv",
        title: "LTV",
        align: "right",
        render: ({ data: { refinanceInfo } }) => <> {formatPercent(refinanceInfo?.ltv ?? 0)} </>,
        sortBy: ({ data: { refinanceInfo } }) => refinanceInfo?.ltv ?? 0
    },
    {
        dataIndex: "liquidationThreshold",
        toolTip: COPY.LIQUIDATION_THRESHOLD_TOOLTIP,
        title: COPY.LIQUIDATION_THRESHOLD_TERM,
        align: "right",
        render: ({ data: { refinanceInfo } }) => <> {formatPercent(refinanceInfo?.liquidationThreshold ?? 0)} </>,
        sortBy: ({ data: { refinanceInfo } }) => refinanceInfo?.liquidationThreshold ?? 0
    }
];

function CollateralApyLabel({ market }: { market: CollateralMarket }) {
    const { metadata } = market;
    const { getPointSources } = useCollateralPointsSourcesUtil();

    const pointSources = getPointSources(metadata.assetMint);

    return <CollateralLabel metadata={metadata} loopscalePointSources={pointSources} />;
}
