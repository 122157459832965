import { AbfLoanExpanded, LoanInfoSorting, RoleView, useBsPrincipalTokens } from "@bridgesplit/abf-react";
import {
    Column,
    EmptyPlaceholder,
    EmptyPlaceholderProps,
    LabelWrapper,
    MEDIA,
    MediaStack,
    Pagination,
    Row,
    Select,
    repeatElement,
    usePopover
} from "@bridgesplit/ui";
import { useLocation } from "react-router-dom";
import { EmptyPromptType, useEmptyPrompts } from "app/utils";
import { PORTFOLIO_BASE } from "app/constants";
import { DEFAULT_LOAN_PAGE_SIZE, DEFAULT_LOAN_PAGE_SIZE_OPTIONS, useLoansPagination } from "app/api";

import LoanCard from "./LoanCard";
import { FilterButtonPopover, LoanFilter, PastActiveToggle, SortDirectionButton, TokenSelect } from "../common";

type PaginationType = ReturnType<typeof useLoansPagination>;

const BREAKPOINT = MEDIA.LG;
export default function LoanCards({
    loans: rawLoans,
    view,
    pagination
}: {
    loans: AbfLoanExpanded[] | undefined;
    defaultFilter?: LoanFilter;
    view: RoleView;
    pagination?: { controller: PaginationType; totalLoans: number | undefined };
}) {
    const loans = rawLoans?.sort((a, b) => a.index - b.index);
    const pageSize = pagination?.controller.params.pageSize;
    return (
        <Column spacing={2}>
            {pagination && (
                <PaginationControls
                    pagination={pagination.controller}
                    pageSize={pageSize}
                    totalLoans={pagination.totalLoans}
                />
            )}
            {loans?.length === 0 && (
                <Empty
                    emptyText={(() => {
                        if (!pagination) return undefined;
                        if (pagination.controller.params.activeOnly) return "No ongoing loans";
                        return "No past loans";
                    })()}
                    view={view}
                />
            )}
            {loans
                ? loans.map((loan: AbfLoanExpanded) => (
                      <LoanCard query={BREAKPOINT} key={loan?.loan.address} loan={loan} />
                  ))
                : repeatElement(<LoanCard query={BREAKPOINT} loan={undefined} />, pageSize)}

            {!!pagination && !!pageSize && !!pagination.totalLoans && pagination.totalLoans > pageSize && (
                <Pagination
                    count={pagination.totalLoans}
                    rowsPerPage={pageSize}
                    page={pagination.controller.page}
                    setPage={pagination.controller.setPage}
                />
            )}
        </Column>
    );
}

const SORT_OPTIONS = [
    { value: LoanInfoSorting.StartTime, label: "Start date" },
    { value: LoanInfoSorting.EndTime, label: "End date" },
    { value: LoanInfoSorting.NextPayment, label: "Next payment" },
    { value: LoanInfoSorting.Principal, label: "Loan size" },
    { value: LoanInfoSorting.LoanHealth, label: "Health" }
];
function PaginationControls({
    pagination,
    pageSize,
    totalLoans
}: {
    pagination: PaginationType;
    pageSize?: number;
    totalLoans?: number;
}) {
    const {
        params: { sortType, sortSide },
        setSortSide,
        setSortType,
        setPage
    } = pagination;
    const popoverProps = usePopover();

    return (
        <MediaStack spaceBetweenRow query={BREAKPOINT} spacing={1}>
            <Row spacing={1}>
                <FilterButtonPopover reset={pagination.reset} popoverProps={popoverProps}>
                    <Filters {...pagination} />
                </FilterButtonPopover>
                <PastActiveToggle
                    value={pagination.params.activeOnly}
                    setValue={(val) => pagination.setActiveOnly(!!val)}
                />
            </Row>

            <Row spacing={1} sx={{ width: "100%", justifyContent: "flex-end" }}>
                {!!pageSize && !!totalLoans && totalLoans > DEFAULT_LOAN_PAGE_SIZE && (
                    <Select
                        sx={{ width: "50px" }}
                        value={pageSize}
                        setValue={(value) => {
                            pagination.setPage(0);
                            pagination.setPageSize(value);
                        }}
                        options={DEFAULT_LOAN_PAGE_SIZE_OPTIONS}
                    />
                )}
                <SortDirectionButton
                    direction={sortSide}
                    setDirection={(sortSide) => {
                        setPage(0);
                        setSortSide(sortSide);
                    }}
                />
                <Select
                    sx={{ [BREAKPOINT.above]: { maxWidth: "230px" } }}
                    value={sortType}
                    setValue={(sort) => {
                        setPage(0);
                        setSortType(sort);
                    }}
                    options={SORT_OPTIONS}
                />
            </Row>
        </MediaStack>
    );
}

function Filters({ setPrincipalMint, params }: PaginationType) {
    const { tokens } = useBsPrincipalTokens();

    return (
        <LabelWrapper label="Token">
            <TokenSelect tokens={tokens} value={params.principalMint} setValue={setPrincipalMint} />
        </LabelWrapper>
    );
}

function Empty({ view, emptyText = "You haven't started any loans" }: { view: RoleView; emptyText?: string }) {
    const getPrompt = useEmptyPrompts();
    const prompt = getPrompt(view === RoleView.Lend ? EmptyPromptType.Lend : EmptyPromptType.Borrow, emptyText);

    const location = useLocation();
    const promptWithCta: EmptyPlaceholderProps = {
        ...prompt,
        cta: location.pathname.includes(PORTFOLIO_BASE) ? prompt.cta : undefined
    };

    return <EmptyPlaceholder {...promptWithCta} />;
}
